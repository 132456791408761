import React, { useEffect, useState } from "react";
import { style } from "./AppsUsersCss";
import {
  Box,
  Paper,
  Typography,
  Divider,
  Grid,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../../helper/AxiosClient";
const AppsUsers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [appData, setAppData] = useState([]);

  // get apps data

  const getAppData = async () => {
    setLoading(true);
    try {
      const res = await getRequest("app/get-app");
      const data = res.data.data;
      setAppData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAppData();
  }, []);

  return (
    <Paper elevation={0} sx={style.mainPadding}>
      <Box sx={style.appHeaderPosition}>
        <Typography sx={style.appHeaderName} px={4} display="flex">
          Apps
        </Typography>
      </Box>
      <Divider sx={style.headerLine}></Divider>
      <Box sx={style.bodyStyleHeight} className="tableScrollNone">
        <Grid>
          {loading ? (
            <Grid item xs={12} sx={style.loaderPosition}>
              <CircularProgress sx={{ color: "#E75126" }} />
            </Grid>
          ) : appData.length === 0 ? (
            <Box sx={style.noDataFoundText}>
              <Typography sx={style.noDataFound}>No Data</Typography>
            </Box>
          ) : (
            <Box sx={style.mainPart}>
              <Paper variant="outlined">
                <TableContainer
                  sx={style.appBoxStyle}
                  className="tableScrollNone"
                >
                  <Table stickyHeader={true} aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={style.tableHeaderTextColor}>
                          No.
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor}>
                          App
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor}>
                          Sub Admin
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor}>
                          App Id
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appData.map((app, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.appFontStyle}
                          >
                            {index + 1}
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Typography
                              width="fit-content"
                              sx={style.appFontStyleCursor}
                              onClick={() =>
                                navigate(`/appsUsers/${app.id}`, {
                                  state: { data: { app_name: app.app_name } },
                                })
                              }
                            >
                              {app.app_name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.appFontStyle}
                          >
                            {app.subadmin ? (
                              <Typography>{app.subadmin.name}</Typography>
                            ) : (
                              <Typography>{app.admin.name}</Typography>
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.appFontStyle}
                          >
                            {app.id}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default AppsUsers;
