export const style = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    width: "30%",
    maxHeight: "90vh",
    overflowY: "auto",
    backgroundColor: "white",
    "@media(max-Width: 600px)": {
      width: "80%",
    },
    padding: "20px",
  },

  inputLabel: {
    color: "black",
    fontWeight: 500,
    fontSize: "16px",
    marginBottom: "0",
  },

  noDataFound: {
    color: "#ACACAC",
    fontSize: "25px",
  },
  mainPart: {
    padding: "20px",
    borderRadius: "20px",
  },
  mainPartSubPart: {
    display: "flex",
    justifyContent: "space-between",
  },
  pageMainTitle: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  tableAddBtnText: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "#0075FF",
    cursor: "pointer",
  },
  headerLine: {
    backgroundColor: "black",
    margin: "20px 0",
  },
  loaderPosition: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px",
  },
  noDataFoundText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100px",
  },
  mainTableWidth: {
    minWidth: 650,
  },
  tableHeaderBgColor: {
    backgroundColor: "#F5F7FB",
  },
  tableHeaderTextColor: {
    color: "#343434",
    fontSize: "16px",
    fontWeight: 600,
  },
  deleteBtnMargin: {
    marginLeft: "10px",
  },

  modalMainPart: {
    padding: "0 0 20px 0",
  },
  modalHeaderStyle: {
    fontWeight: "600",
  },
  subCategoryRemoveIcon: {
    cursor: "pointer",
    position: "absolute",
    top: "-7px",
    right: "-7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: "50%",
    height: "14px",
    width: "14px",
  },
  addBtnStyle: {
    display: "flex",
    justifyContent: "end",
  },
  submitLoading: {
    color: "#000",
    marginRight: "10px",
  },
};
