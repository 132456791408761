import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Divider,
  Grid,
  Modal,
  CircularProgress,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  deleteRequest,
  getCurrentUser,
  getRequest,
  patchRequest,
  postRequest,
} from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { RiCloseFill } from "react-icons/ri";
import { style } from "./AppMulticss";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../assets/images/edit-2.svg";
import deleteIcon from "../../../assets/images/trash.png";
import InputField from "../../comman/component/CustomInput";
import ConfirmationDeleteModal from "../../comman/component/ConfirmationDeleteModal";

const AppMulti = () => {
  const navigate = useNavigate();
  const [appData, setAppData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [appName, setAppName] = useState("");
  const [userDataStore, setUserDataStore] = useState("");
  const [deleteModalTableId, setDeleteModalTableId] = useState("");
  const [modalAppName, setModalAppName] = useState("");

  // get apps data

  const getAppData = async () => {
    setLoading(true);
    try {
      const res = await getRequest("app/get-app");
      const data = res.data.data;
      setAppData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // get user data for dropdown

  const getUserData = async () => {
    setLoading(true);
    try {
      const res = await getRequest("admin/subadmin-list");
      const data = res.data.data;
      setUserData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAppData();
    getUserData();
  }, []);

  // app data delete

  const handleDeleteAppsData = async () => {
    setSubmitLoading(true);
    try {
      const response = await deleteRequest(
        `app/delete-app/${deleteModalTableId}`
      );
      if (response.response.data.success === true) {
        successNotification(response.data?.message);
        handleDeleteCloseConfirmModal();
        getAppData();
      } else {
        errorNotification(response.data.errormessage);
      }
      setSubmitLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // edit app data event

  const handleEditAppsData = async (editRowData) => {
    setEditedId(editRowData.id);
    setAppName(editRowData.app_name);
    if (
      editRowData?.subadmin?.name &&
      editRowData.subadmin.name === getCurrentUser()?.name
    ) {
      setUserDataStore("");
    } else {
      setUserDataStore(editRowData.subadmin_id);
    }
    setOpenModal(true);
  };

  // edit edit add data

  const handleSubmit = async (e) => {
    setSubmitLoading(true);
    e.preventDefault();
    if (editedId) {
      const data = {
        app_name: appName,
        subadmin_id: userDataStore,
      };
      try {
        const response = await patchRequest(`app/update-app/${editedId}`, data);
        if (response?.data?.success === true) {
          handleCloseModal();
          setEditedId(null);
          successNotification(response.data?.message);
          getAppData();
          setSubmitLoading(false);
          setAppName("");
          setUserDataStore("");
        } else {
          errorNotification(response.data.errormessage);
          setSubmitLoading(false);
        }
        setSubmitLoading(false);
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    } else {
      try {
        const data = {
          app_name: appName,
          subadmin_id: userDataStore ? userDataStore : getCurrentUser().id,
        };
        const res = await postRequest("app/create-app", data, null);
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          handleCloseModal();
          getAppData();
          setAppName("");
          setUserDataStore("");
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
        }
        setSubmitLoading(false);
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    }
  };

  // close modal

  const handleCloseModal = () => {
    setEditedId(null);
    setOpenModal(false);
    setAppName("");
    setUserDataStore("");
  };

  // open modal event

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // user dropdown handle change

  const handleChange = (event) => {
    setUserDataStore(event.target.value);
  };

  // delete icon click event

  const handleDeleteModal = (row) => {
    setModalAppName(row.app_name);
    setDeleteModalTableId(row.id);
    setDeleteModal(true);
  };

  // delete confirm modal close

  const handleDeleteCloseConfirmModal = () => {
    setDeleteModal(false);
  };

  return (
    <>
      <Paper elevation={0} sx={style.mainPadding}>
        <Box sx={style.appHeaderPosition}>
          <Typography sx={style.appHeaderName} px={4} display="flex">
            Apps
          </Typography>
          <Button
            variant="outlined"
            sx={style.addBtnStyleOne}
            onClick={handleOpenModal}
          >
            ADD
          </Button>
        </Box>
        <Divider sx={style.headerLine}></Divider>
        <Box sx={style.bodyStyleHeight} className="tableScrollNone">
          <Grid>
            {loading ? (
              <Grid item xs={12} sx={style.loaderPosition}>
                <CircularProgress sx={{ color: "#E75126" }} />
              </Grid>
            ) : appData.length === 0 ? (
              <Box sx={style.noDataFoundText}>
                <Typography sx={style.noDataFound}>No Data</Typography>
              </Box>
            ) : (
              <Box sx={style.mainPart}>
                <Paper variant="outlined">
                  <TableContainer
                    sx={style.appBoxStyle}
                    className="tableScrollNone"
                  >
                    <Table stickyHeader={true} aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={style.tableHeaderTextColor}>
                            No.
                          </TableCell>
                          <TableCell sx={style.tableHeaderTextColor}>
                            App
                          </TableCell>
                          <TableCell sx={style.tableHeaderTextColor}>
                            Sub Admin
                          </TableCell>
                          <TableCell sx={style.tableHeaderTextColor}>
                            App Id
                          </TableCell>
                          <TableCell
                            sx={style.tableHeaderTextColor}
                            align="right"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {appData.map((app, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.appFontStyle}
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell component="th" scope="row">
                              <Typography
                                width="fit-content"
                                sx={style.appFontStyleCursor}
                                onClick={() =>
                                  navigate(`/apps/${app.id}`, {
                                    state: { data: { app_name: app.app_name } },
                                  })
                                }
                              >
                                {app.app_name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.appFontStyle}
                            >
                              {app.subadmin ? (
                                <Typography>{app.subadmin.name}</Typography>
                              ) : (
                                <Typography>{app.admin.name}</Typography>
                              )}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.appFontStyle}
                            >
                              {app.id}
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                display="flex"
                                justifyContent="end"
                                sx={style.appFontStyleCursor}
                              >
                                <img
                                  src={editIcon}
                                  onClick={() => handleEditAppsData(app)}
                                />
                                <Typography pl={3}>
                                  <img
                                    src={deleteIcon}
                                    onClick={() => handleDeleteModal(app)}
                                  />
                                </Typography>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <ConfirmationDeleteModal
                  handleDeleteCloseConfirmModal={handleDeleteCloseConfirmModal}
                  deleteModal={deleteModal}
                  handleDeleteApiCall={handleDeleteAppsData}
                  text={`Are you sure Delete this "${modalAppName}" app?`}
                  submitLoading={submitLoading}
                />
              </Box>
            )}
          </Grid>
        </Box>
      </Paper>

      {/* modal  */}

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              sx={style.modalMainPart}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={style.modalHeaderStyle}>
                {editedId ? "Edit" : "Add"} App
              </Box>
              <Box textAlign="end">
                <RiCloseFill
                  onClick={() => handleCloseModal()}
                  size="22"
                  cursor="pointer"
                />
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <InputField
                  type="text"
                  required={true}
                  value={appName}
                  placeholder="Enter App Name"
                  onChange={(e) => setAppName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <Select
                  value={userDataStore}
                  fullWidth
                  size="small"
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Select sub admin</MenuItem>
                  {userData.map((cur) => (
                    <MenuItem key={cur.id} value={cur.id}>
                      {cur.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                sx={style.addBtnStyle}
                mb={2}
                mt={2}
                display="flex"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={style.submitBtnStyle}
                  disabled={submitLoading}
                >
                  {submitLoading && (
                    <CircularProgress
                      size={16}
                      sx={{ marginRight: "10px", color: "#E75126" }}
                    />
                  )}
                  {editedId ? "Save" : "Add"}
                </Button>
                <Button
                  variant="outlined"
                  sx={style.cancelBtn}
                  onClick={() => handleCloseModal()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AppMulti;
