import loginImage from "../../../assets/images/login.svg";

export const style = {
  loginSection: {
    height: "100vh",
    width: "100vw",
  },
  container: {
    maxWidth: "100%",
  },
  row: {
    display: "flex",
    height: "100%",
  },
  loginImage: {
    height: "100vh",
    width: "100%",
    flex: "1",
    backgroundImage: `url(${loginImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  rightContainer: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginForm: {
    minWidth: "420px",
  },
  loginTitle: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "5",
  },
  mb3: {
    marginBottom: "15px",
  },
  eyeIcons: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  loginButton: {
    marginTop: "25px",
  },
  addBtnStyleOne: {
    color: "#E75126",
    borderColor: "#D2D2D2",
    height: "36px",
    paddingRight: "20px",
    paddingLeft: "20px",
    textTransform: "capitalize",
    "&:hover": {
      borderColor: "#E75126",
      backgroundColor: "#fcf1eb",
    },
  },
  spinner: {
    marginRight: "2",
  },
  passwordWrapper: {
    position: "relative",
    display: "flex",
  },
};
