import React, { useEffect, useState } from "react";
import { style } from "./Categorycss";
import {
  Paper,
  Typography,
  Button,
  Divider,
  Box,
  Grid,
  Modal,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import {
  baseURL,
  getCurrentUser,
  getRequest,
} from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import axios from "axios";
import editIcon from "../../../assets/images/edit-2.svg";
import deleteIcon from "../../../assets/images/trash.png";
import InputField from "../../comman/component/CustomInput";
import backArrow from "../../../assets/images/arrow-left.png";
import { RiCloseFill } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmationDeleteModal from "../../comman/component/ConfirmationDeleteModal";
import { AiOutlineClose } from "react-icons/ai";
import Papa from "papaparse";

const CategoryTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [csvFile, setCsvFile] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editedId, setEditedId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [csvModal, setCsvModal] = useState(false);
  const [categoryNameData, setCategoryNameData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [deleteModalTableId, setDeleteModalTableId] = useState("");
  const [modalCategoryName, setModalCategoryName] = useState("");
  const [icon, setIcon] = useState("");
  const minCategoryTitle = location?.state?.data?.app_name;

  // get category data

  const getCategoryData = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`admin/get-category-list?app_id=${id}`);
      setLoading(false);
      setCategoryNameData(res.data.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getCategoryData();
  }, []);

  // edit category event

  const handleEditCategoryData = async (editRowData) => {
    setEditedId(editRowData.id);
    setCategoryName(editRowData.categoryName);
    setOpenModal(true);
    setIcon(editRowData.icon);
  };

  // category add and edit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    if (editedId) {
      try {
        const formData = new FormData();
        icon && typeof icon !== "string" && formData.append("icon", icon);
        icon && formData.append("isIconNull", null);
        formData.append("isIconNull", 1);
        formData.append("categoryName", categoryName);
        formData.append("app_id", id);
        formData.append("slug", categorySlug);
        formData.append("categoryId", editedId);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        };
        const res = await axios.patch(
          `${baseURL}admin/update-category`,
          formData,
          config
        );
        if (res?.data?.success === true) {
          handleCloseModal();
          setEditedId(null);
          successNotification(res.data?.message);
          setSubmitLoading(false);
          setCategoryName("");
          setCategorySlug("");
          getCategoryData();
          setIcon("");
        } else {
          errorNotification(res.data.errormessage);
          setSubmitLoading(false);
        }
        setSubmitLoading(false);
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    } else {
      try {
        const formData = new FormData();
        icon && formData.append("icon", icon);
        formData.append("categoryName", categoryName);
        formData.append("app_id", id);
        formData.append("slug", categorySlug);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        };
        const res = await axios.post(
          `${baseURL}admin/add-category`,
          formData,
          config
        );
        if (res?.data?.success === true) {
          successNotification(res?.data?.message);
          setEditedId(null);
          handleCloseModal();
          getCategoryData();
          setCategoryName("");
          setCategorySlug("");
          getCategoryData();
          setIcon("");
        } else {
          errorNotification(res.errormessage);
        }
        setSubmitLoading(false);
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    }
  };

  // delete category

  const handleDeleteCategory = async () => {
    setSubmitLoading(true);
    const payload = {
      categoryId: deleteModalTableId,
      app_id: id,
    };
    try {
      await axios.delete(`${baseURL}admin/delete-category`, {
        headers: {
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
        data: payload,
      });
      getCategoryData();
      handleDeleteCloseConfirmModal();
      setSubmitLoading(false);
    } catch (error) {
      console.error("Error deleting category:", error);
      setSubmitLoading(false);
    }
  };

  // close modal

  const handleCloseModal = () => {
    setEditedId(null);
    setOpenModal(false);
    setCategoryName("");
    setCategorySlug("");
    setIcon("");
  };

  // open modal event

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // delete icon click event

  const handleDeleteModal = (row) => {
    setModalCategoryName(row.categoryName);
    setDeleteModalTableId(row.id);
    setDeleteModal(true);
  };

  // delete close confirm modal

  const handleDeleteCloseConfirmModal = () => {
    setDeleteModal(false);
  };

  //  open csv modal

  const handleOpenCsvModal = () => {
    setCsvModal(true);
  };

  // close csv modal

  const handleClose = () => {
    setCsvModal(false);
    setCSVData([]);
  };

  // csv file target

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
    Papa.parse(file, {
      complete: (result) => {
        setCSVData(result.data);
      },
      header: true,
    });
  };

  // upload csv file

  const uploadCsvFile = async () => {
    setSubmitLoading(true);
    try {
      const formData = new FormData();
      formData.append("app_id", id);
      formData.append("file", csvFile);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      };
      const response = await axios.post(
        `${baseURL}admin/add-category-csv`,
        formData,
        config
      );
      if (response?.data?.success === true) {
        successNotification(response.data?.message);
        setCsvModal(false);
        setSubmitLoading(false);
        getCategoryData();
      } else {
        errorNotification(response.data.errormessage);
        setSubmitLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setCSVData([]);
  };

  // image remove event

  const handleRemoveIcon = () => {
    setIcon("");
  };

  // image select

  const handleSelectIcon = (e) => {
    setIcon(e.target.files[0]);
  };
  return (
    <>
      <Paper elevation={0} sx={style.mainPadding}>
        <Box sx={style.headerPosition}>
          <Typography
            sx={style.categoryHeaderName}
            display="flex"
            alignItems="center"
          >
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/apps")}
            >
              <img src={backArrow} />
            </Typography>
            <Typography px={2}>{minCategoryTitle}</Typography>
          </Typography>
          <Box>
            <Button
              variant="outlined"
              sx={style.addBtnStyleOne}
              onClick={handleOpenModal}
            >
              ADD
            </Button>
            <Button
              variant="outlined"
              sx={style.addBtnStyleOne}
              onClick={handleOpenCsvModal}
            >
              ADD CSV
            </Button>
          </Box>
        </Box>
        <Divider sx={style.headerLine} />
        <Box sx={style.bodyStyleHeight} className="tableScrollNone">
          {loading ? (
            <Box sx={style.loaderPosition}>
              <CircularProgress sx={{ color: "#E75126" }} />
            </Box>
          ) : categoryNameData.length === 0 ? (
            <Box sx={style.noDataFoundText}>
              <Typography sx={style.noDataFound}>No Data</Typography>
            </Box>
          ) : (
            <Box sx={style.mainPart}>
              <Paper variant="outlined">
                <TableContainer
                  sx={style.categoryBoxStyle}
                  className="tableScrollNone"
                >
                  <Table stickyHeader={true} aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={style.tableHeaderTextColor}>
                          No.
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor}>
                          Category
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor} align="left">
                          slug
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor} align="left">
                          Icon
                        </TableCell>
                        <TableCell
                          sx={style.tableHeaderTextColor}
                          align="right"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categoryNameData.map((category, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.categoryFontStyle}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography
                              width="fit-content"
                              sx={style.categoryFontStyleCursor}
                              onClick={() =>
                                navigate(`/apps/${id}/${category.id}`, {
                                  state: {
                                    data: { category: category.categoryName },
                                  },
                                })
                              }
                            >
                              {category.categoryName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.categoryFontStyle}
                          >
                            {category.slug}
                          </TableCell>
                          {category.icon ? (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.categoryFontStyle}
                            >
                              <img
                                src={category.icon}
                                height="25px"
                                width="25px"
                              ></img>
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.categoryFontStyle}
                            ></TableCell>
                          )}
                          <TableCell align="right">
                            <Typography
                              display="flex"
                              justifyContent="end"
                              sx={style.categoryFontStyleCursor}
                            >
                              <img
                                src={editIcon}
                                onClick={() => handleEditCategoryData(category)}
                              />
                              <Typography pl={3}>
                                <img
                                  src={deleteIcon}
                                  onClick={() => handleDeleteModal(category)}
                                />
                              </Typography>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          )}
        </Box>
        <ConfirmationDeleteModal
          handleDeleteCloseConfirmModal={handleDeleteCloseConfirmModal}
          deleteModal={deleteModal}
          handleDeleteApiCall={handleDeleteCategory}
          text={`Are you sure delete this "${modalCategoryName}" category?`}
          submitLoading={submitLoading}
        />
      </Paper>

      {/* modal  */}

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <form onSubmit={handleSubmit}>
            <Grid
              sx={style.modalMainPart}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={style.modalHeaderStyle}>
                {editedId ? "Update" : "Add"} Category
              </Box>
              <Box>
                <RiCloseFill
                  onClick={() => handleCloseModal()}
                  size="22"
                  cursor="pointer"
                />
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <InputField
                  type="text"
                  required={true}
                  value={categoryName}
                  placeholder="Enter Category Name"
                  onChange={(e) => {
                    const inputText = e.target.value;
                    const capitalizedText =
                      inputText.charAt(0).toUpperCase() + inputText.slice(1);
                    setCategoryName(capitalizedText);
                  }}
                />
              </Grid>
              {!editedId && (
                <Grid item xs={12} mt={1}>
                  <InputField
                    type="text"
                    required={true}
                    value={categorySlug}
                    placeholder="Enter Category Slug"
                    onChange={(e) => setCategorySlug(e.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sx={{ display: "flex" }}>
                {!icon && (
                  <Button
                    variant="contained"
                    component="label"
                    type="button"
                    sx={{
                      backgroundColor: "#E75126",
                      padding: "6px 25px",
                      textTransform: "capitalize",
                      fontSize: "18px",
                      borderRadius: "10px",
                      "&:hover": {
                        bgcolor: "#E75126",
                      },
                    }}
                  >
                    Select Image
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => handleSelectIcon(e)}
                    />
                  </Button>
                )}
                {icon && (
                  <Box sx={{ position: "relative" }}>
                    {typeof icon === "string" ? (
                      <>
                        <img
                          style={{ marginLeft: "20px" }}
                          src={icon}
                          alt="notFound"
                          height={40}
                          width={40}
                        />
                      </>
                    ) : (
                      <img
                        style={{ marginLeft: "20px" }}
                        src={URL.createObjectURL(icon)}
                        alt={icon.name}
                        height={40}
                        width={40}
                      />
                    )}
                    <div
                      style={style.subCategoryRemoveIcon}
                      onClick={() => handleRemoveIcon()}
                    >
                      <AiOutlineClose style={{ fontSize: "12px" }} />
                    </div>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={style.addBtnStyle}
                mb={2}
                mt={2}
                display="flex"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={style.submitBtnStyle}
                  disabled={submitLoading}
                >
                  {submitLoading && (
                    <CircularProgress
                      size={16}
                      sx={{ marginRight: "10px", color: "#E75126" }}
                    />
                  )}
                  {editedId ? "Save" : "Add"}
                </Button>
                <Button
                  variant="outlined"
                  sx={style.cancelBtn}
                  onClick={() => handleCloseModal()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      {/* csv file upload modal  */}

      <Modal
        open={csvModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.csvModal}>
          <Grid
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <label htmlFor="csv-file-input">
              <Button
                component="span"
                variant="contained"
                sx={style.csvBtnStyle}
              >
                Choose CSV File
              </Button>
            </label>
            <Box item xs={2} textAlign="end">
              <RiCloseFill
                onClick={() => handleClose()}
                size="22"
                cursor="pointer"
              />
            </Box>
          </Grid>
          <input
            type="file"
            accept=".csv"
            id="csv-file-input"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          {csvData.length > 0 && (
            <Box
              my={csvData.length > 0 && 2}
              sx={style.dataOverFlow}
              className="tableScrollNone"
            >
              <Paper elevation={0} variant="outlined">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {Object.keys(csvData[0]).map((header) => (
                          <TableCell sx={{ fontSize: "16px" }} key={header}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {csvData.map((row, index) => (
                        <TableRow key={index}>
                          {Object.values(row).map((cell, cellIndex) => (
                            <TableCell key={cellIndex}>{cell}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          )}
          {csvData.length !== 0 && (
            <Button
              onClick={uploadCsvFile}
              variant="contained"
              sx={style.csvBtnStyle}
              disabled={submitLoading}
            >
              {submitLoading && (
                <CircularProgress
                  size={16}
                  sx={{ marginRight: "10px", color: "#E75126" }}
                />
              )}
              Upload
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default CategoryTwo;
