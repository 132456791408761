/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReviewOption = /* GraphQL */ `
  query GetReviewOption($id: ID!) {
    getReviewOption(id: $id) {
      id
      label
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReviewOptions = /* GraphQL */ `
  query ListReviewOptions(
    $filter: ModelReviewOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReviewOptions = /* GraphQL */ `
  query SyncReviewOptions(
    $filter: ModelReviewOptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviewOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        label
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      userID
      type
      ReviewOption {
        id
        label
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rating
      description
      Pet {
        id
        name
        username
        type
        breed
        gender
        dob
        status
        rating
        reviews
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petProfileImgId
        petCoverPhotoId
        petLocationId
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reviewReviewOptionId
      reviewPetId
      reviewBusinessId
      __typename
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        type
        rating
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        reviewReviewOptionId
        reviewPetId
        reviewBusinessId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReviews = /* GraphQL */ `
  query SyncReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        type
        rating
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        reviewReviewOptionId
        reviewPetId
        reviewBusinessId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClaim = /* GraphQL */ `
  query GetClaim($id: ID!) {
    getClaim(id: $id) {
      id
      firstname
      lastname
      phone
      email
      description
      status
      assetType
      assetID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClaims = /* GraphQL */ `
  query ListClaims(
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        phone
        email
        description
        status
        assetType
        assetID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClaims = /* GraphQL */ `
  query SyncClaims(
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClaims(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstname
        lastname
        phone
        email
        description
        status
        assetType
        assetID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFollow = /* GraphQL */ `
  query GetFollow($id: ID!) {
    getFollow(id: $id) {
      id
      status
      FollowID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFollows = /* GraphQL */ `
  query ListFollows(
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        FollowID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFollows = /* GraphQL */ `
  query SyncFollows(
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFollows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        FollowID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      assetID
      type
      assetType
      description
      reason
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assetID
        type
        assetType
        description
        reason
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReports = /* GraphQL */ `
  query SyncReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        assetID
        type
        assetType
        description
        reason
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPollResponse = /* GraphQL */ `
  query GetPollResponse($id: ID!) {
    getPollResponse(id: $id) {
      id
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      pollResponseUserId
      __typename
    }
  }
`;
export const listPollResponses = /* GraphQL */ `
  query ListPollResponses(
    $filter: ModelPollResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPollResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        pollResponseUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPollResponses = /* GraphQL */ `
  query SyncPollResponses(
    $filter: ModelPollResponseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPollResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        pollResponseUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPoll = /* GraphQL */ `
  query GetPoll($id: ID!) {
    getPoll(id: $id) {
      id
      label
      description
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPolls = /* GraphQL */ `
  query ListPolls(
    $filter: ModelPollFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        description
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPolls = /* GraphQL */ `
  query SyncPolls(
    $filter: ModelPollFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPolls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        label
        description
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWelcome = /* GraphQL */ `
  query GetWelcome($id: ID!) {
    getWelcome(id: $id) {
      id
      value
      feedID
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      welcomeUserId
      __typename
    }
  }
`;
export const listWelcomes = /* GraphQL */ `
  query ListWelcomes(
    $filter: ModelWelcomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWelcomes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        welcomeUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWelcomes = /* GraphQL */ `
  query SyncWelcomes(
    $filter: ModelWelcomeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWelcomes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        value
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        welcomeUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFriendShip = /* GraphQL */ `
  query GetFriendShip($id: ID!) {
    getFriendShip(id: $id) {
      id
      status
      description
      senderID
      Users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFriendShips = /* GraphQL */ `
  query ListFriendShips(
    $filter: ModelFriendShipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriendShips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        description
        senderID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFriendShips = /* GraphQL */ `
  query SyncFriendShips(
    $filter: ModelFriendShipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFriendShips(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        description
        senderID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      type
      description
      bucket
      region
      key
      url
      messageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        description
        bucket
        region
        key
        url
        messageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMedia = /* GraphQL */ `
  query SyncMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedia(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        description
        bucket
        region
        key
        url
        messageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUpload = /* GraphQL */ `
  query GetUpload($id: ID!) {
    getUpload(id: $id) {
      id
      type
      description
      bucket
      region
      key
      url
      assetType
      assetID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUploads = /* GraphQL */ `
  query ListUploads(
    $filter: ModelUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        description
        bucket
        region
        key
        url
        assetType
        assetID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUploads = /* GraphQL */ `
  query SyncUploads(
    $filter: ModelUploadFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUploads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        description
        bucket
        region
        key
        url
        assetType
        assetID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      createdAt
      content
      status
      userID
      chatroomID
      Media {
        nextToken
        startedAt
        __typename
      }
      type
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        content
        status
        userID
        chatroomID
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        content
        status
        userID
        chatroomID
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatRoomUser = /* GraphQL */ `
  query GetChatRoomUser($id: ID!) {
    getChatRoomUser(id: $id) {
      id
      chatrooms {
        nextToken
        startedAt
        __typename
      }
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomUserUserId
      __typename
    }
  }
`;
export const listChatRoomUsers = /* GraphQL */ `
  query ListChatRoomUsers(
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRoomUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomUserUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatRoomUsers = /* GraphQL */ `
  query SyncChatRoomUsers(
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatRoomUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomUserUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      ChatRoomUsers {
        nextToken
        startedAt
        __typename
      }
      Messages {
        nextToken
        startedAt
        __typename
      }
      lastMessage {
        id
        createdAt
        content
        status
        userID
        chatroomID
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomLastMessageId
      __typename
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatRooms = /* GraphQL */ `
  query SyncChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      title
      excerpt
      description
      type
      category
      employmentStatus
      companyName
      url
      deadline
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CompanyLogo {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      JobFields {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobLocationId
      jobImageId
      jobCompanyLogoId
      __typename
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        excerpt
        description
        type
        category
        employmentStatus
        companyName
        url
        deadline
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobLocationId
        jobImageId
        jobCompanyLogoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJobs = /* GraphQL */ `
  query SyncJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        excerpt
        description
        type
        category
        employmentStatus
        companyName
        url
        deadline
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobLocationId
        jobImageId
        jobCompanyLogoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJobField = /* GraphQL */ `
  query GetJobField($id: ID!) {
    getJobField(id: $id) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      jobID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobFieldProfileFieldId
      __typename
    }
  }
`;
export const listJobFields = /* GraphQL */ `
  query ListJobFields(
    $filter: ModelJobFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        privacy
        jobID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobFieldProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJobFields = /* GraphQL */ `
  query SyncJobFields(
    $filter: ModelJobFieldFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        value
        privacy
        jobID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobFieldProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeal = /* GraphQL */ `
  query GetDeal($id: ID!) {
    getDeal(id: $id) {
      id
      title
      excerpt
      type
      amount
      percentage
      slug
      description
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dealLocationId
      dealImageId
      dealBusinessId
      __typename
    }
  }
`;
export const listDeals = /* GraphQL */ `
  query ListDeals(
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        excerpt
        type
        amount
        percentage
        slug
        description
        status
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dealLocationId
        dealImageId
        dealBusinessId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDeals = /* GraphQL */ `
  query SyncDeals(
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        excerpt
        type
        amount
        percentage
        slug
        description
        status
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dealLocationId
        dealImageId
        dealBusinessId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($id: ID!) {
    getNews(id: $id) {
      id
      title
      excerpt
      type
      category
      source
      url
      imageUrl
      description
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      newsLocationId
      newsImageId
      __typename
    }
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews(
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        excerpt
        type
        category
        source
        url
        imageUrl
        description
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        newsLocationId
        newsImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNews = /* GraphQL */ `
  query SyncNews(
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        excerpt
        type
        category
        source
        url
        imageUrl
        description
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        newsLocationId
        newsImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBlockUser = /* GraphQL */ `
  query GetBlockUser($id: ID!) {
    getBlockUser(id: $id) {
      id
      reason
      description
      userID
      blockID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBlockUsers = /* GraphQL */ `
  query ListBlockUsers(
    $filter: ModelBlockUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reason
        description
        userID
        blockID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBlockUsers = /* GraphQL */ `
  query SyncBlockUsers(
    $filter: ModelBlockUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBlockUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        reason
        description
        userID
        blockID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      type
      slug
      description
      startDate
      startTime
      endDate
      endTime
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      EventCategories {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventLocationId
      eventImageId
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        slug
        description
        startDate
        startTime
        endDate
        endTime
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventLocationId
        eventImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        type
        slug
        description
        startDate
        startTime
        endDate
        endTime
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventLocationId
        eventImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEventCategory = /* GraphQL */ `
  query GetEventCategory($id: ID!) {
    getEventCategory(id: $id) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      eventID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventCategoryCategoryId
      __typename
    }
  }
`;
export const listEventCategories = /* GraphQL */ `
  query ListEventCategories(
    $filter: ModelEventCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        eventID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventCategoryCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEventCategories = /* GraphQL */ `
  query SyncEventCategories(
    $filter: ModelEventCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEventCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        description
        eventID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventCategoryCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      title
      type
      slug
      description
      status
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      GroupSetting {
        id
        invite
        post
        photo
        video
        document
        message
        notification
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      GroupMembers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      groupLocationId
      groupImageId
      groupBusinessId
      groupGroupSettingId
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        slug
        description
        status
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        groupLocationId
        groupImageId
        groupBusinessId
        groupGroupSettingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        type
        slug
        description
        status
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        groupLocationId
        groupImageId
        groupBusinessId
        groupGroupSettingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGroupSetting = /* GraphQL */ `
  query GetGroupSetting($id: ID!) {
    getGroupSetting(id: $id) {
      id
      invite
      post
      photo
      video
      document
      message
      notification
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGroupSettings = /* GraphQL */ `
  query ListGroupSettings(
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invite
        post
        photo
        video
        document
        message
        notification
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGroupSettings = /* GraphQL */ `
  query SyncGroupSettings(
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroupSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        invite
        post
        photo
        video
        document
        message
        notification
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGroupMember = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      role
      status
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      groupMemberUserId
      __typename
    }
  }
`;
export const listGroupMembers = /* GraphQL */ `
  query ListGroupMembers(
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        role
        status
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        groupMemberUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGroupMembers = /* GraphQL */ `
  query SyncGroupMembers(
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroupMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupID
        role
        status
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        groupMemberUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      title
      type
      slug
      description
      price
      sale
      free
      area
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      productImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ProductImages {
        nextToken
        startedAt
        __typename
      }
      ProductCategories {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productLocationId
      productProductImgId
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        slug
        description
        price
        sale
        free
        area
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productLocationId
        productProductImgId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        type
        slug
        description
        price
        sale
        free
        area
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productLocationId
        productProductImgId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProductImage = /* GraphQL */ `
  query GetProductImage($id: ID!) {
    getProductImage(id: $id) {
      id
      title
      description
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productImageImageId
      __typename
    }
  }
`;
export const listProductImages = /* GraphQL */ `
  query ListProductImages(
    $filter: ModelProductImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productImageImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProductImages = /* GraphQL */ `
  query SyncProductImages(
    $filter: ModelProductImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productImageImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProductCategory = /* GraphQL */ `
  query GetProductCategory($id: ID!) {
    getProductCategory(id: $id) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productCategoryCategoryId
      __typename
    }
  }
`;
export const listProductCategories = /* GraphQL */ `
  query ListProductCategories(
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productCategoryCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProductCategories = /* GraphQL */ `
  query SyncProductCategories(
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        description
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productCategoryCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBusinessCategory = /* GraphQL */ `
  query GetBusinessCategory($id: ID!) {
    getBusinessCategory(id: $id) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessCategoryCategoryId
      __typename
    }
  }
`;
export const listBusinessCategories = /* GraphQL */ `
  query ListBusinessCategories(
    $filter: ModelBusinessCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        businessID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessCategoryCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBusinessCategories = /* GraphQL */ `
  query SyncBusinessCategories(
    $filter: ModelBusinessCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBusinessCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        description
        businessID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessCategoryCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBusinessImage = /* GraphQL */ `
  query GetBusinessImage($id: ID!) {
    getBusinessImage(id: $id) {
      id
      title
      descripti
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessImageImageId
      __typename
    }
  }
`;
export const listBusinessImages = /* GraphQL */ `
  query ListBusinessImages(
    $filter: ModelBusinessImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        descripti
        businessID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessImageImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBusinessImages = /* GraphQL */ `
  query SyncBusinessImages(
    $filter: ModelBusinessImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBusinessImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        descripti
        businessID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessImageImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBusinessField = /* GraphQL */ `
  query GetBusinessField($id: ID!) {
    getBusinessField(id: $id) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessFieldProfileFieldId
      __typename
    }
  }
`;
export const listBusinessFields = /* GraphQL */ `
  query ListBusinessFields(
    $filter: ModelBusinessFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        privacy
        businessID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessFieldProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBusinessFields = /* GraphQL */ `
  query SyncBusinessFields(
    $filter: ModelBusinessFieldFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBusinessFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        value
        privacy
        businessID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessFieldProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      id
      title
      type
      slug
      description
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      coverPhoto {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      rating
      reviews
      claimed
      deeplink
      BusinessImages {
        nextToken
        startedAt
        __typename
      }
      BusinessCategories {
        nextToken
        startedAt
        __typename
      }
      BusinessFields {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessLocationId
      businessProfileImgId
      businessCoverPhotoId
      __typename
    }
  }
`;
export const listBusinesses = /* GraphQL */ `
  query ListBusinesses(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBusinesses = /* GraphQL */ `
  query SyncBusinesses(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBusinesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPetImage = /* GraphQL */ `
  query GetPetImage($id: ID!) {
    getPetImage(id: $id) {
      id
      title
      description
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      petID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petImageImageId
      __typename
    }
  }
`;
export const listPetImages = /* GraphQL */ `
  query ListPetImages(
    $filter: ModelPetImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPetImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        petID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petImageImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPetImages = /* GraphQL */ `
  query SyncPetImages(
    $filter: ModelPetImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPetImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        petID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petImageImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPetField = /* GraphQL */ `
  query GetPetField($id: ID!) {
    getPetField(id: $id) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      petID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petFieldProfileFieldId
      __typename
    }
  }
`;
export const listPetFields = /* GraphQL */ `
  query ListPetFields(
    $filter: ModelPetFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPetFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        privacy
        petID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petFieldProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPetFields = /* GraphQL */ `
  query SyncPetFields(
    $filter: ModelPetFieldFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPetFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        value
        privacy
        petID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petFieldProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPet = /* GraphQL */ `
  query GetPet($id: ID!) {
    getPet(id: $id) {
      id
      name
      username
      type
      breed
      gender
      dob
      status
      rating
      reviews
      userID
      profileImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      coverPhoto {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      PetImages {
        nextToken
        startedAt
        __typename
      }
      PetFields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petProfileImgId
      petCoverPhotoId
      petLocationId
      __typename
    }
  }
`;
export const listPets = /* GraphQL */ `
  query ListPets(
    $filter: ModelPetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        username
        type
        breed
        gender
        dob
        status
        rating
        reviews
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petProfileImgId
        petCoverPhotoId
        petLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPets = /* GraphQL */ `
  query SyncPets(
    $filter: ModelPetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        username
        type
        breed
        gender
        dob
        status
        rating
        reviews
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petProfileImgId
        petCoverPhotoId
        petLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      slug
      type
      description
      logo {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cover {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      categoryLogoId
      categoryCoverId
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserOption = /* GraphQL */ `
  query GetUserOption($id: ID!) {
    getUserOption(id: $id) {
      id
      value
      Option {
        id
        slug
        description
        title
        options
        instruction
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userOptionOptionId
      __typename
    }
  }
`;
export const listUserOptions = /* GraphQL */ `
  query ListUserOptions(
    $filter: ModelUserOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userOptionOptionId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserOptions = /* GraphQL */ `
  query SyncUserOptions(
    $filter: ModelUserOptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        value
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userOptionOptionId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      id
      slug
      description
      title
      options
      instruction
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $filter: ModelOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        description
        title
        options
        instruction
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOptions = /* GraphQL */ `
  query SyncOptions(
    $filter: ModelOptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        slug
        description
        title
        options
        instruction
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserAddress = /* GraphQL */ `
  query GetUserAddress($id: ID!) {
    getUserAddress(id: $id) {
      id
      title
      description
      instruction
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAddressLocationId
      __typename
    }
  }
`;
export const listUserAddresses = /* GraphQL */ `
  query ListUserAddresses(
    $filter: ModelUserAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        instruction
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAddressLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserAddresses = /* GraphQL */ `
  query SyncUserAddresses(
    $filter: ModelUserAddressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        instruction
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAddressLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      address
      city
      province
      zip
      lat
      lng
      country
      instruction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getView = /* GraphQL */ `
  query GetView($id: ID!) {
    getView(id: $id) {
      id
      type
      decription
      feedID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listViews = /* GraphQL */ `
  query ListViews(
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        decription
        feedID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncViews = /* GraphQL */ `
  query SyncViews(
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncViews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        decription
        feedID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getShare = /* GraphQL */ `
  query GetShare($id: ID!) {
    getShare(id: $id) {
      id
      type
      feedID
      description
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listShares = /* GraphQL */ `
  query ListShares(
    $filter: ModelShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShares(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        feedID
        description
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncShares = /* GraphQL */ `
  query SyncShares(
    $filter: ModelShareFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShares(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        feedID
        description
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      type
      feedID
      description
      userID
      feedcommentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        feedID
        description
        userID
        feedcommentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLikes = /* GraphQL */ `
  query SyncLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLikes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        feedID
        description
        userID
        feedcommentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      url
      type
      description
      bucket
      region
      key
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImages = /* GraphQL */ `
  query SyncImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReel = /* GraphQL */ `
  query GetReel($id: ID!) {
    getReel(id: $id) {
      id
      url
      type
      description
      bucket
      region
      key
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReels = /* GraphQL */ `
  query ListReels(
    $filter: ModelReelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReels = /* GraphQL */ `
  query SyncReels(
    $filter: ModelReelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFriend = /* GraphQL */ `
  query GetFriend($id: ID!) {
    getFriend(id: $id) {
      id
      memberID
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      friendUserId
      __typename
    }
  }
`;
export const listFriends = /* GraphQL */ `
  query ListFriends(
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        friendUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFriends = /* GraphQL */ `
  query SyncFriends(
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFriends(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        memberID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        friendUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      bio
      status
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Friends {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      memberUserId
      __typename
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bio
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        memberUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMembers = /* GraphQL */ `
  query SyncMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bio
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        memberUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFeedComment = /* GraphQL */ `
  query GetFeedComment($id: ID!) {
    getFeedComment(id: $id) {
      id
      content
      parent
      feedID
      Likes {
        nextToken
        startedAt
        __typename
      }
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      feedCommentLocationId
      feedCommentUserId
      __typename
    }
  }
`;
export const listFeedComments = /* GraphQL */ `
  query ListFeedComments(
    $filter: ModelFeedCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        parent
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        feedCommentLocationId
        feedCommentUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFeedComments = /* GraphQL */ `
  query SyncFeedComments(
    $filter: ModelFeedCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeedComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        content
        parent
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        feedCommentLocationId
        feedCommentUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFeed = /* GraphQL */ `
  query GetFeed($id: ID!) {
    getFeed(id: $id) {
      id
      content
      userID
      type
      privacy
      deeplink
      FeedComments {
        nextToken
        startedAt
        __typename
      }
      Images {
        nextToken
        startedAt
        __typename
      }
      Reels {
        nextToken
        startedAt
        __typename
      }
      Shares {
        nextToken
        startedAt
        __typename
      }
      Likes {
        nextToken
        startedAt
        __typename
      }
      Views {
        nextToken
        startedAt
        __typename
      }
      Welcomes {
        nextToken
        startedAt
        __typename
      }
      Polls {
        nextToken
        startedAt
        __typename
      }
      sharedAssetID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      feedLocationId
      __typename
    }
  }
`;
export const listFeeds = /* GraphQL */ `
  query ListFeeds(
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        userID
        type
        privacy
        deeplink
        sharedAssetID
        groupID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        feedLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFeeds = /* GraphQL */ `
  query SyncFeeds(
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        content
        userID
        type
        privacy
        deeplink
        sharedAssetID
        groupID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        feedLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserField = /* GraphQL */ `
  query GetUserField($id: ID!) {
    getUserField(id: $id) {
      id
      value
      privacy
      userID
      UserProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userFieldUserProfileFieldId
      __typename
    }
  }
`;
export const listUserFields = /* GraphQL */ `
  query ListUserFields(
    $filter: ModelUserFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        privacy
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userFieldUserProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserFields = /* GraphQL */ `
  query SyncUserFields(
    $filter: ModelUserFieldFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        value
        privacy
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userFieldUserProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserProfileField = /* GraphQL */ `
  query GetUserProfileField($id: ID!) {
    getUserProfileField(id: $id) {
      id
      title
      description
      placeholder
      slug
      instruction
      type
      visibility
      profileType
      profileGroup
      position
      status
      required
      fieldOptions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserProfileFields = /* GraphQL */ `
  query ListUserProfileFields(
    $filter: ModelUserProfileFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfileFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserProfileFields = /* GraphQL */ `
  query SyncUserProfileFields(
    $filter: ModelUserProfileFieldFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserProfileFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProfileField = /* GraphQL */ `
  query GetProfileField($id: ID!) {
    getProfileField(id: $id) {
      id
      title
      description
      placeholder
      slug
      instruction
      type
      visibility
      profileType
      profileGroup
      position
      status
      required
      fieldOptions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listProfileFields = /* GraphQL */ `
  query ListProfileFields(
    $filter: ModelProfileFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProfileFields = /* GraphQL */ `
  query SyncProfileFields(
    $filter: ModelProfileFieldFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfileFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cid
      username
      firstname
      lastname
      email
      phone
      about
      bio
      dob
      gender
      role
      age
      accountType
      status
      deeplink
      avatar
      coverPhoto
      language
      lastActive
      UserFields {
        nextToken
        startedAt
        __typename
      }
      Feeds {
        nextToken
        startedAt
        __typename
      }
      Views {
        nextToken
        startedAt
        __typename
      }
      Shares {
        nextToken
        startedAt
        __typename
      }
      Likes {
        nextToken
        startedAt
        __typename
      }
      UserAddresses {
        nextToken
        startedAt
        __typename
      }
      UserOptions {
        nextToken
        startedAt
        __typename
      }
      Pets {
        nextToken
        startedAt
        __typename
      }
      Businesses {
        nextToken
        startedAt
        __typename
      }
      Products {
        nextToken
        startedAt
        __typename
      }
      Events {
        nextToken
        startedAt
        __typename
      }
      Groups {
        nextToken
        startedAt
        __typename
      }
      News {
        nextToken
        startedAt
        __typename
      }
      Deals {
        nextToken
        startedAt
        __typename
      }
      BlockUsers {
        nextToken
        startedAt
        __typename
      }
      Jobs {
        nextToken
        startedAt
        __typename
      }
      Messages {
        nextToken
        startedAt
        __typename
      }
      FriendShips {
        nextToken
        startedAt
        __typename
      }
      Reports {
        nextToken
        startedAt
        __typename
      }
      Follows {
        nextToken
        startedAt
        __typename
      }
      Claims {
        nextToken
        startedAt
        __typename
      }
      Reviews {
        nextToken
        startedAt
        __typename
      }
      Uploads {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFilterCategory = /* GraphQL */ `
  query GetFilterCategory($id: ID!) {
    getFilterCategory(id: $id) {
      id
      categoryName
      subCategory {
        id
        subCategoryName
        __typename
      }
      icon
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listFilterCategories = /* GraphQL */ `
  query ListFilterCategories(
    $filter: ModelFilterCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFilterCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryName
        icon
        subCategory {
          id
          subCategoryName
      }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFilterCategories = /* GraphQL */ `
  query SyncFilterCategories(
    $filter: ModelFilterCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFilterCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        categoryName
        icon
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPostCategory = /* GraphQL */ `
  query GetPostCategory($id: ID!) {
    getPostCategory(id: $id) {
      id
      postCategoryName
      icon
      postSubCategory {
        id
        postSubCategoryName
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPostCategories = /* GraphQL */ `
  query ListPostCategories(
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postCategoryName
        icon
        postSubCategory {
          id
          postSubCategoryName
      }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPostCategories = /* GraphQL */ `
  query SyncPostCategories(
    $filter: ModelPostCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPostCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        postCategoryName
        icon
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserFriendShip = /* GraphQL */ `
  query GetUserFriendShip($id: ID!) {
    getUserFriendShip(id: $id) {
      id
      friendShipId
      userId
      friendShip {
        id
        status
        description
        senderID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserFriendShips = /* GraphQL */ `
  query ListUserFriendShips(
    $filter: ModelUserFriendShipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFriendShips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        friendShipId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserFriendShips = /* GraphQL */ `
  query SyncUserFriendShips(
    $filter: ModelUserFriendShipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserFriendShips(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        friendShipId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatRoomChatRoomUser = /* GraphQL */ `
  query GetChatRoomChatRoomUser($id: ID!) {
    getChatRoomChatRoomUser(id: $id) {
      id
      chatRoomUserId
      chatRoomId
      chatRoomUser {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomUserUserId
        __typename
      }
      chatRoom {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatRoomChatRoomUsers = /* GraphQL */ `
  query ListChatRoomChatRoomUsers(
    $filter: ModelChatRoomChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRoomChatRoomUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomUserId
        chatRoomId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatRoomChatRoomUsers = /* GraphQL */ `
  query SyncChatRoomChatRoomUsers(
    $filter: ModelChatRoomChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatRoomChatRoomUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        chatRoomUserId
        chatRoomId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reviewsByUserID = /* GraphQL */ `
  query ReviewsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        type
        rating
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        reviewReviewOptionId
        reviewPetId
        reviewBusinessId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const claimsByUserID = /* GraphQL */ `
  query ClaimsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    claimsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lastname
        phone
        email
        description
        status
        assetType
        assetID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const followsByUserID = /* GraphQL */ `
  query FollowsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        FollowID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByUserID = /* GraphQL */ `
  query ReportsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetID
        type
        assetType
        description
        reason
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pollsByFeedID = /* GraphQL */ `
  query PollsByFeedID(
    $feedID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPollFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pollsByFeedID(
      feedID: $feedID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        description
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const welcomesByFeedID = /* GraphQL */ `
  query WelcomesByFeedID(
    $feedID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWelcomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    welcomesByFeedID(
      feedID: $feedID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        welcomeUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mediaByMessageID = /* GraphQL */ `
  query MediaByMessageID(
    $messageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mediaByMessageID(
      messageID: $messageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        description
        bucket
        region
        key
        url
        messageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const uploadsByUserID = /* GraphQL */ `
  query UploadsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        description
        bucket
        region
        key
        url
        assetType
        assetID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const messagesByUserID = /* GraphQL */ `
  query MessagesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        content
        status
        userID
        chatroomID
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const messagesByChatroomID = /* GraphQL */ `
  query MessagesByChatroomID(
    $chatroomID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChatroomID(
      chatroomID: $chatroomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        content
        status
        userID
        chatroomID
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const messagesByDate = /* GraphQL */ `
  query MessagesByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        content
        status
        userID
        chatroomID
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByUserID = /* GraphQL */ `
  query JobsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        excerpt
        description
        type
        category
        employmentStatus
        companyName
        url
        deadline
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobLocationId
        jobImageId
        jobCompanyLogoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobsByDate = /* GraphQL */ `
  query JobsByDate(
    $table: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByDate(
      table: $table
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        excerpt
        description
        type
        category
        employmentStatus
        companyName
        url
        deadline
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobLocationId
        jobImageId
        jobCompanyLogoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const jobFieldsByJobID = /* GraphQL */ `
  query JobFieldsByJobID(
    $jobID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobFieldsByJobID(
      jobID: $jobID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        privacy
        jobID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        jobFieldProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByUserID = /* GraphQL */ `
  query DealsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        excerpt
        type
        amount
        percentage
        slug
        description
        status
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dealLocationId
        dealImageId
        dealBusinessId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const newsByUserID = /* GraphQL */ `
  query NewsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    newsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        excerpt
        type
        category
        source
        url
        imageUrl
        description
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        newsLocationId
        newsImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const newsByDate = /* GraphQL */ `
  query NewsByDate(
    $table: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    newsByDate(
      table: $table
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        excerpt
        type
        category
        source
        url
        imageUrl
        description
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        newsLocationId
        newsImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const blockUsersByUserID = /* GraphQL */ `
  query BlockUsersByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockUsersByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reason
        description
        userID
        blockID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const eventsByUserID = /* GraphQL */ `
  query EventsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        slug
        description
        startDate
        startTime
        endDate
        endTime
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventLocationId
        eventImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const eventsByDate = /* GraphQL */ `
  query EventsByDate(
    $table: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByDate(
      table: $table
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        slug
        description
        startDate
        startTime
        endDate
        endTime
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventLocationId
        eventImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const eventCategoriesByEventID = /* GraphQL */ `
  query EventCategoriesByEventID(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventCategoriesByEventID(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        eventID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        eventCategoryCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const groupsByUserID = /* GraphQL */ `
  query GroupsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        slug
        description
        status
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        groupLocationId
        groupImageId
        groupBusinessId
        groupGroupSettingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const groupMembersByGroupID = /* GraphQL */ `
  query GroupMembersByGroupID(
    $groupID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupMembersByGroupID(
      groupID: $groupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupID
        role
        status
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        groupMemberUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productsByUserID = /* GraphQL */ `
  query ProductsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        slug
        description
        price
        sale
        free
        area
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productLocationId
        productProductImgId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productByDate = /* GraphQL */ `
  query ProductByDate(
    $table: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByDate(
      table: $table
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        slug
        description
        price
        sale
        free
        area
        status
        userID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productLocationId
        productProductImgId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productImagesByProductID = /* GraphQL */ `
  query ProductImagesByProductID(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productImagesByProductID(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productImageImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productCategoriesByProductID = /* GraphQL */ `
  query ProductCategoriesByProductID(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productCategoriesByProductID(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        productID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productCategoryCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const businessCategoriesByBusinessID = /* GraphQL */ `
  query BusinessCategoriesByBusinessID(
    $businessID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessCategoriesByBusinessID(
      businessID: $businessID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        businessID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessCategoryCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const businessImagesByBusinessID = /* GraphQL */ `
  query BusinessImagesByBusinessID(
    $businessID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessImagesByBusinessID(
      businessID: $businessID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        descripti
        businessID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessImageImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const businessFieldsByBusinessID = /* GraphQL */ `
  query BusinessFieldsByBusinessID(
    $businessID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessFieldsByBusinessID(
      businessID: $businessID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        privacy
        businessID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessFieldProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const businessesByUserID = /* GraphQL */ `
  query BusinessesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const businessByDate = /* GraphQL */ `
  query BusinessByDate(
    $table: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    businessByDate(
      table: $table
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const petImagesByPetID = /* GraphQL */ `
  query PetImagesByPetID(
    $petID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPetImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    petImagesByPetID(
      petID: $petID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        petID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petImageImageId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const petFieldsByPetID = /* GraphQL */ `
  query PetFieldsByPetID(
    $petID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPetFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    petFieldsByPetID(
      petID: $petID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        privacy
        petID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petFieldProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const petsByUserID = /* GraphQL */ `
  query PetsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    petsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        username
        type
        breed
        gender
        dob
        status
        rating
        reviews
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petProfileImgId
        petCoverPhotoId
        petLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userOptionsByUserID = /* GraphQL */ `
  query UserOptionsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userOptionsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userOptionOptionId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userAddressesByUserID = /* GraphQL */ `
  query UserAddressesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAddressesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        instruction
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userAddressLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const viewsByFeedID = /* GraphQL */ `
  query ViewsByFeedID(
    $feedID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    viewsByFeedID(
      feedID: $feedID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        decription
        feedID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const viewsByUserID = /* GraphQL */ `
  query ViewsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    viewsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        decription
        feedID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const sharesByFeedID = /* GraphQL */ `
  query SharesByFeedID(
    $feedID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sharesByFeedID(
      feedID: $feedID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        feedID
        description
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const sharesByUserID = /* GraphQL */ `
  query SharesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sharesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        feedID
        description
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const likesByFeedID = /* GraphQL */ `
  query LikesByFeedID(
    $feedID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByFeedID(
      feedID: $feedID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        feedID
        description
        userID
        feedcommentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const likesByUserID = /* GraphQL */ `
  query LikesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        feedID
        description
        userID
        feedcommentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const likesByFeedcommentID = /* GraphQL */ `
  query LikesByFeedcommentID(
    $feedcommentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByFeedcommentID(
      feedcommentID: $feedcommentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        feedID
        description
        userID
        feedcommentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const imagesByFeedID = /* GraphQL */ `
  query ImagesByFeedID(
    $feedID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByFeedID(
      feedID: $feedID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reelsByFeedID = /* GraphQL */ `
  query ReelsByFeedID(
    $feedID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reelsByFeedID(
      feedID: $feedID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const friendsByMemberID = /* GraphQL */ `
  query FriendsByMemberID(
    $memberID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    friendsByMemberID(
      memberID: $memberID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberID
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        friendUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const feedCommentsByFeedID = /* GraphQL */ `
  query FeedCommentsByFeedID(
    $feedID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFeedCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedCommentsByFeedID(
      feedID: $feedID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        parent
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        feedCommentLocationId
        feedCommentUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const feedsByUserID = /* GraphQL */ `
  query FeedsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        userID
        type
        privacy
        deeplink
        sharedAssetID
        groupID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        feedLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const feedsByDate = /* GraphQL */ `
  query FeedsByDate(
    $table: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedsByDate(
      table: $table
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        userID
        type
        privacy
        deeplink
        sharedAssetID
        groupID
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        feedLocationId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userFieldsByUserID = /* GraphQL */ `
  query UserFieldsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFieldsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        value
        privacy
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userFieldUserProfileFieldId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userFriendShipsByFriendShipId = /* GraphQL */ `
  query UserFriendShipsByFriendShipId(
    $friendShipId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFriendShipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFriendShipsByFriendShipId(
      friendShipId: $friendShipId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendShipId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userFriendShipsByUserId = /* GraphQL */ `
  query UserFriendShipsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFriendShipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFriendShipsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        friendShipId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const chatRoomChatRoomUsersByChatRoomUserId = /* GraphQL */ `
  query ChatRoomChatRoomUsersByChatRoomUserId(
    $chatRoomUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomChatRoomUsersByChatRoomUserId(
      chatRoomUserId: $chatRoomUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomUserId
        chatRoomId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const chatRoomChatRoomUsersByChatRoomId = /* GraphQL */ `
  query ChatRoomChatRoomUsersByChatRoomId(
    $chatRoomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomChatRoomUsersByChatRoomId(
      chatRoomId: $chatRoomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomUserId
        chatRoomId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
