import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { API, Storage } from "aws-amplify";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiCloseFill } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import {
  createPostCategory,
  deletePostCategory,
  updatePostCategory,
} from "../../../graphql/mutations";
import { listPostCategories } from "../../../graphql/queries";
import InputField from "../../comman/component/CustomInput";
import { style } from "../../../css/comman";

const PostCategory = () => {
  const postCategoryOption = [
    { label: "Business Page", value: "business" },
    { label: "Watercraft", value: "watercraft" },
    { label: "Job Posting", value: "jobs" },
  ];
  const [categoryData, setCategoryData] = useState({ name: "business" });
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [editedId, setEditedId] = useState(null);
  const [filterCategoryData, setFilterCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subCategory, setSubCategory] = useState("");

  // add btn modal event

  const handleAddCategory = () => {
    setOpenModal(true);
  };

  // edit and add data

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    if (editedId) {
      // const fileName = `images/filterCategory/${categoryData.icon.name}`;
      // await Storage.put(fileName, categoryData.icon)
      try {
        await API.graphql({
          query: updatePostCategory,
          variables: {
            input: {
              id: editedId,
              postCategoryName: categoryData.name,
              postSubCategory: subCategoryData,
              // icon: categoryData.icon,
              _version: editedData["_version"],
            },
          },
        });
        setEditedId(null);
        setEditedData(null);
        fetchFilterCategory();
      } catch (e) {
        console.log("error", e);
      } finally {
        handleCloseModal();
        setSubmitLoading(false);
      }
    } else {
      // const fileName = `images/postCategory/${categoryData.icon.name}`;
      // await Storage.put(fileName, categoryData.icon)
      try {
        await API.graphql({
          query: createPostCategory,
          variables: {
            input: {
              postCategoryName: categoryData.name,
              postSubCategory: subCategoryData,
              icon: categoryData.name,
            },
          },
        });
        fetchFilterCategory();
      } catch (e) {
        console.log("error", e);
      } finally {
        handleCloseModal();
        setSubmitLoading(false);
      }
    }
  };

  const handleDeleteSubCategory = (chipId) => () => {
    setSubCategoryData((chips) => chips.filter((chip) => chip.id !== chipId));
  };

  const handleAddSubCategory = () => {
    if (!subCategory) {
      return false;
    }
    setSubCategoryData([
      ...subCategoryData,
      { id: uuidv4(), postSubCategoryName: subCategory },
    ]);
    setSubCategory("");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCategoryData({ name: "", icon: "" });
    setSubCategory("");
    setSubCategoryData([]);
    setEditedId(null);
    setEditedData(null);
  };

  const fetchFilterCategory = async () => {
    setLoading(true);
    const res = await API.graphql({ query: listPostCategories });
    const filterCatData = res.data.listPostCategories.items.filter(
      (cat) => !cat._deleted
    );

    for (let i = 0; i < filterCatData.length; i++) {
      const imageUrl = await Storage.get(filterCatData[i].icon);
      filterCatData[i].icon = imageUrl;
    }
    setFilterCategoryData(filterCatData);
    setLoading(false);
    // console.log("res", res.data.listFilterCategories.items)
  };

  // post category delete data

  const handleDeleteFilterCategory = async (rowData) => {
    const newCatList = filterCategoryData.filter(
      (cat) => cat.id !== rowData.id
    );
    try {
      await API.graphql({
        query: deletePostCategory,
        variables: {
          input: {
            id: rowData.id,
            _version: rowData["_version"],
          },
        },
      });
      setFilterCategoryData(newCatList);
    } catch (e) {
      console.log("err", e);
    }
  };

  // post category edit data

  const handleEditFilterCategory = async (editRowData) => {
    const { id, postCategoryName, postSubCategory, icon } = editRowData;
    setEditedId(id);
    setEditedData(editRowData);
    setSubCategoryData(postSubCategory);
    setCategoryData({ name: postCategoryName, icon: icon });
    setOpenModal(true);
  };

  useEffect(() => {
    fetchFilterCategory();
  }, []);

  return (
    <>
      <Paper elevation={0} sx={style.mainPart}>
        <Box sx={style.mainPartSubPart}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "bold", fontSize: "18px" }}
          >
            Post Category
          </Typography>
          <Typography
            variant="subtitle2"
            sx={style.tableAddBtnText}
            onClick={() => handleAddCategory()}
          >
            Add
          </Typography>
        </Box>
        <Divider sx={style.headerLine} />
        <TableContainer>
          {loading ? (
            <Box sx={style.loaderPosition}>
              <CircularProgress />
            </Box>
          ) : filterCategoryData.length === 0 ? (
            <Box sx={style.noDataFoundText}>
              <Typography sx={style.noDataFound}>No Data</Typography>
            </Box>
          ) : (
            <Table sx={style.mainTableWidth} aria-label="simple table">
              <TableHead sx={style.tableHeaderBgColor}>
                <TableRow>
                  <TableCell sx={style.tableHeaderTextColor}>
                    Post Category
                  </TableCell>
                  <TableCell sx={style.tableHeaderTextColor} align="right">
                    Post Sub category
                  </TableCell>
                  <TableCell sx={style.tableHeaderTextColor} align="right">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterCategoryData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.postCategoryName}
                    </TableCell>
                    <TableCell align="right">
                      {row.postSubCategory
                        .map((subCat) => subCat.postSubCategoryName)
                        .toString()}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleEditFilterCategory(row)}
                      >
                        Edit
                      </Button>
                      <Button
                        sx={{ marginLeft: "10px" }}
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => handleDeleteFilterCategory(row)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>

      {/* ---------- add category modal --------- */}
      <Modal
        // onClose={handleCurrencyModal}
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <form onSubmit={handleSubmit}>
            <Grid container sx={style.modalMainPart}>
              <Grid item xs={10} sx={style.modalHeaderStyle}>
                {editedId ? "Edit" : "Add"} Post Category
              </Grid>
              <Grid item xs={2} textAlign="end">
                <RiCloseFill onClick={() => handleCloseModal()} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <Typography sx={style.inputLabel} mb={1}>
                    Post Category Name
                  </Typography>
                  <Select
                    value={categoryData.name}
                    onChange={(e) =>
                      setCategoryData({ ...categoryData, name: e.target.value })
                    }
                  >
                    {postCategoryOption.map((opt) => (
                      <MenuItem value={opt.value}>{opt.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid item xs={11}>
                  <InputField
                    type="text"
                    label="Sub Category Name"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                >
                  <AiOutlinePlusCircle
                    style={{
                      fontSize: "30px",
                      color: "rgba(0,0,0,0.3)",
                      marginBottom: "5px",
                    }}
                    onClick={() => handleAddSubCategory()}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <Box sx={{ width: "100%", paddingTop: "0px" }}>
                  {subCategoryData.length !== 0 &&
                    subCategoryData.map((data, index) => {
                      return (
                        <Chip
                          key={index}
                          label={data.postSubCategoryName}
                          onDelete={handleDeleteSubCategory(data.id)}
                          sx={{ marginRight: "8px", marginTop: "8px" }}
                        />
                      );
                    })}
                </Box>
              </Grid>
              <Grid item xs={12} mt={2} sx={style.addBtnStyle}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={submitLoading}
                >
                  {submitLoading && (
                    <CircularProgress size={16} sx={style.submitLoading} />
                  )}
                  {editedId ? "Save" : "Add"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default PostCategory;
