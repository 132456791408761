export const style = {
  headerLine: {
    backgroundColor: "#D2D2D2",
  },
  mainPadding: {
    paddingBottom: "20px",
    "@media(max-Width: 600px)": {
      paddingBottom: "10px",
    },
  },
  userHeaderPosition: {
    display: "flex",
    padding: "12px",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    "@media(max-Width: 600px)": {
      padding: "0px",
    },
  },
  mainPart: {
    padding: "20px 34px 0px 34px",
    "@media(max-Width: 600px)": {
      padding: "15px 18px 0px 18px",
    },
  },
  bodyStyleHeight: {
    height: "calc(100vh - 186px)",
    overflow: "auto",
  },
  usersBoxStyle: {
    fontSize: "18px",
    maxHeight: "calc(100vh - 210px)",
  },
  tableHeaderTextColor: {
    fontSize: "18px",
    color: "black",
    backgroundColor: "#fcf1eb",
    "@media(max-Width: 600px)": {
      fontSize: "16px",
    },
  },
  loaderPosition: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 250px)",
  },
  noDataFoundText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 250px)",
  },
  noDataFound: {
    color: "#ACACAC",
    fontSize: "25px",
  },
  userFontStyle: {
    fontSize: "16px",
    "@media(max-Width: 600px)": {
      fontSize: "14px",
    },
  },
  userFontStyleCursor: {
    fontSize: "16px",
  },
  usersHeaderName: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#000000",
    marginLeft: "10px",
  },
};
