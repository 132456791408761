import React, { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../helper/AxiosClient";
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Grid,
  Modal,
} from "@mui/material";
import editIcon from "../../../assets/images/edit-2.svg";
import deleteIcon from "../../../assets/images/trash.png";
import { RiCloseFill } from "react-icons/ri";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import InputField from "../../comman/component/CustomInput";
import { style } from "./ProfileTypesCss";

const ProfileTypes = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [profileType, setProfileType] = useState([]);
  const [profileName, setProfileName] = useState("");

  // Get profile type data

  const getprofileType = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`profile/get-profile?app_id=${2}`);
      const data = res.data.data.profile;
      setProfileType(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getprofileType();
  }, []);

  // edit profile type

  const handleEditprofileType = async (editRowData) => {
    setEditedId(editRowData.id);
    setProfileName(editRowData.label);
    setOpenModal(true);
  };

  // add profile type data

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        label: profileName,
        app_id: 2,
      };
      const res = await postRequest("profile/add-profile", data, null);
      if (res.type === 1) {
        successNotification(res?.response?.data?.message);
        handleCloseModal();
        getprofileType();
        setProfileName(" ");
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
      }
      setSubmitLoading(false);
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
    }
  };

  // add btn modal open

  const handleAddCategory = () => {
    setOpenModal(true);
  };

  // modal close events

  const handleCloseModal = () => {
    setEditedId(null);
    setOpenModal(false);
    setProfileName(" ");
  };
  return (
    <>
      <Paper elevation={0} sx={style.mainPadding}>
        <Box sx={style.profileHeaderPosition}>
          <Typography sx={style.profileHeaderName} px={4} display="flex">
            Profile Types
          </Typography>
          <Button
            variant="outlined"
            sx={style.addBtnStyleOne}
            onClick={() => handleAddCategory()}
          >
            Add
          </Button>
        </Box>
        <Divider sx={style.headerLine} />
        <Box sx={style.bodyStyleHeight} className="tableScrollNone">
          <Grid>
            {loading ? (
              <Grid item xs={12} sx={style.loaderPosition}>
                <CircularProgress sx={{ color: "#E75126" }} />
              </Grid>
            ) : profileType.length === 0 ? (
              <Box sx={style.noDataFoundText}>
                <Typography sx={style.noDataFound}>No Data</Typography>
              </Box>
            ) : (
              <Box sx={style.mainPart}>
                <Paper variant="outlined">
                  <TableContainer
                    sx={style.languageBoxStyle}
                    className="tableScrollNone"
                  >
                    <Table stickyHeader={true} aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={style.tableHeaderTextColor}>
                            No.
                          </TableCell>
                          <TableCell sx={style.tableHeaderTextColor}>
                            Profile Types
                          </TableCell>
                          <TableCell sx={style.tableHeaderTextColor}>
                            Slug
                          </TableCell>
                          <TableCell
                            sx={style.tableHeaderTextColor}
                            align="right"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {profileType.map((profile, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.profileFontStyle}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.profileFontStyle}
                            >
                              {profile.label}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.profileFontStyle}
                            >
                              {profile.slug}
                            </TableCell>

                            <TableCell align="right">
                              <Typography
                                display="flex"
                                justifyContent="end"
                                sx={style.profileFontStyleCursor}
                              >
                                <img src={editIcon} />
                                <Typography pl={3}>
                                  <img src={deleteIcon} />
                                </Typography>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            )}
          </Grid>
        </Box>
      </Paper>

      {/* modal  */}

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <form onSubmit={handleSubmit}>
            <Grid container sx={style.modalMainPart}>
              <Grid item xs={10} sx={style.modalHeaderStyle} mt={2}>
                {editedId ? "Edit" : "Add"} Profile Type
              </Grid>
              <Grid item xs={2} textAlign="end">
                <RiCloseFill onClick={() => handleCloseModal()} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={3}>
                <InputField
                  type="text"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sx={style.addBtnStyle} mb={5} mt={1}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={style.submitBtnStyle}
                  disabled={submitLoading}
                >
                  {submitLoading && (
                    <CircularProgress size={16} sx={style.submitLoading} />
                  )}
                  {editedId ? "Save" : "Add"}
                </Button>
                <Button
                  variant="outlined"
                  sx={style.cancelBtn}
                  onClick={() => handleCloseModal()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileTypes;
