import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { style } from "./UserProfileCss.jsx";
import { Box, Paper, Typography, Grid, CircularProgress } from "@mui/material";
import backArrow from "../../../assets/images/arrow-left.png";
import { makeStyles } from "@mui/styles";
import { getRequest } from "../../../helper/AxiosClient";
import userProfileIcon from "../../../assets/images/user.png";

const useStyles = makeStyles((theme) => ({
  avatarStyle: {
    borderRadius: "50%",
    height: "200px",
    width: "200px",
    border: "2px solid gray",
    [theme.breakpoints.down("xl")]: {
      height: "200px",
      width: "200px",
    },
    [theme.breakpoints.down("md")]: {
      height: "180px",
      width: "180px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "150px",
      width: "150px",
    },
  },
}));

const UserProfile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { appUsersId } = useParams();
  const { userId } = useParams();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const minAppTitle = location?.state?.data?.username;

  // get user data

  const getUsersData = async () => {
    setLoading(true);
    try {
      const res = await getRequest(
        `admin/get-user-details/${userId}?app_id=${appUsersId}`
      );
      setUserInfo(res.data.data.info);
      setUserAddress(res.data.data.info.UserAddresses.items);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getUsersData();
  }, []);

  return (
    <>
      <Paper elevation={0} sx={style.mainPadding}>
        {loading ? (
          <Grid item xs={12} sx={style.loaderPosition}>
            <CircularProgress sx={{ color: "#E75126" }} />
          </Grid>
        ) : (
          <>
            <Box sx={style.userProfileHeaderPosition}>
              <Typography
                sx={style.userProfileHeaderName}
                display="flex"
                alignItems="center"
              >
                <Typography
                  src={backArrow}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  <img src={backArrow} />
                </Typography>
                <Typography px={2}>
                  {minAppTitle ? minAppTitle : "-"}
                </Typography>
              </Typography>
            </Box>
            <Box sx={style.bodyStyleHeight} className="tableScrollNone">
              <Box sx={style.userProfileGridSystem}>
                <Grid container spacing={6}>
                  <Grid
                    item
                    xs={12}
                    xl={2.1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <img
                      src={
                        userInfo?.avatar ? userInfo?.avatar : userProfileIcon
                      }
                      className={classes.avatarStyle}
                    />
                    <Typography mt={3}>
                      {userInfo?.firstname && userInfo?.lastname
                        ? `${userInfo?.firstname} ${userInfo?.lastname}`
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} xl={3.3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        First Name
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.firstname ? userInfo?.firstname : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        Phone
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.phone ? userInfo?.phone : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        Gender
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.gender ? userInfo?.gender : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        AdditionalInformation
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.additionalInformation
                          ? userInfo?.additionalInformation
                          : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        AccountType
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.accountType ? userInfo?.accountType : "-"}
                      </Typography>
                    </Box>
                    {userAddress.map((userAddress) => {
                      return (
                        <>
                          <Box
                            mt={5}
                            display="flex"
                            flexDirection="column"
                            sx={{ borderBottom: "1px dotted gray" }}
                          >
                            <Typography
                              sx={{ color: "gray", fontSize: "16px" }}
                            >
                              Country
                            </Typography>
                            <Typography sx={style.wordWrap}>
                              {userAddress?.location?.country
                                ? userAddress?.location?.country
                                : "-"}
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                  </Grid>
                  <Grid item xs={6} xl={3.3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        Last Name
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.lastname ? userInfo?.lastname : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        Bio
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.bio ? userInfo?.bio : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        About
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.about ? userInfo?.about : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        Status
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.status ? userInfo?.status : "-"}
                      </Typography>
                    </Box>
                    {userAddress.map((userAddress) => {
                      return (
                        <>
                          <Box
                            mt={5}
                            display="flex"
                            flexDirection="column"
                            sx={{ borderBottom: "1px dotted gray" }}
                          >
                            <Typography
                              sx={{ color: "gray", fontSize: "16px" }}
                            >
                              Address
                            </Typography>
                            <Typography sx={style.wordWrap}>
                              {userAddress?.location?.address
                                ? userAddress?.location?.address
                                : "-"}
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                    {userAddress.map((userAddress) => {
                      return (
                        <>
                          <Box
                            mt={5}
                            display="flex"
                            flexDirection="column"
                            sx={{ borderBottom: "1px dotted gray" }}
                          >
                            <Typography
                              sx={{ color: "gray", fontSize: "16px" }}
                            >
                              Instruction
                            </Typography>
                            <Typography sx={style.wordWrap}>
                              {userAddress?.location?.instruction
                                ? userAddress?.location?.instruction
                                : "-"}
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                  </Grid>
                  <Grid item xs={6} xl={3.3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        Email
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.email ? userInfo?.email : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        Age
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.age ? userInfo?.age : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        DOB
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.dob ? userInfo?.dob : "-"}
                      </Typography>
                    </Box>
                    <Box
                      mt={5}
                      display="flex"
                      flexDirection="column"
                      sx={{ borderBottom: "1px dotted gray" }}
                    >
                      <Typography sx={{ color: "gray", fontSize: "16px" }}>
                        Language
                      </Typography>
                      <Typography sx={style.wordWrap}>
                        {userInfo?.language ? userInfo?.language : "-"}
                      </Typography>
                    </Box>
                    {userAddress.map((userAddress) => {
                      return (
                        <>
                          <Box
                            mt={5}
                            display="flex"
                            flexDirection="column"
                            sx={{ borderBottom: "1px dotted gray" }}
                          >
                            <Typography
                              sx={{ color: "gray", fontSize: "16px" }}
                            >
                              City
                            </Typography>
                            <Typography sx={style.wordWrap}>
                              {userAddress?.location?.city
                                ? userAddress?.location?.city
                                : "-"}
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                    {userAddress.map((userAddress) => {
                      return (
                        <>
                          <Box
                            mt={5}
                            display="flex"
                            flexDirection="column"
                            sx={{ borderBottom: "1px dotted gray" }}
                          >
                            <Typography
                              sx={{ color: "gray", fontSize: "16px" }}
                            >
                              Province
                            </Typography>
                            <Typography sx={style.wordWrap}>
                              {userAddress?.location?.province
                                ? userAddress?.location?.province
                                : "-"}
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </Paper>
    </>
  );
};

export default UserProfile;
