import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Divider,
  CircularProgress,
  Grid,
  Modal,
} from "@mui/material";
import { RiCloseFill } from "react-icons/ri";
const style = {
  deleteTitle: {
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "16px",
    "@media(max-Width: 450px)": {
      fontSize: "18px",
    },
  },
  cancelBtn: {
    backgroundColor: "#9e9e9e",
    padding: "6px 30px",
    fontSize: "18px",
    borderRadius: "10px",
    textTransform: "capitalize",
    "@media(max-Width: 450px)": {
      padding: "3px 30px",
      fontSize: "16px",
    },
    ":hover": {
      bgcolor: "#9e9e9e",
      color: "white",
    },
  },
  deleteBtn: {
    backgroundColor: "#dc3545",
    color: "black",
    padding: "6px 30px",
    fontSize: "18px",
    marginLeft: "20px",
    borderRadius: "10px",
    color: "white",
    textTransform: "capitalize",
    "@media(max-Width: 450px)": {
      padding: "3px 20px",
      fontSize: "16px",
      marginLeft: "5px",
    },
    ":hover": {
      bgcolor: "#dc3545",
      color: "white",
    },
  },
};
const ConfirmationDeleteModal = ({
  handleDeleteCloseConfirmModal,
  deleteModal,
  handleDeleteApiCall,
  text,
  submitLoading,
}) => {
  return (
    <Dialog
      onClose={handleDeleteCloseConfirmModal}
      open={deleteModal}
      maxWidth="sm"
      fullWidth
    >
      <Grid
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: "20px",
        }}
      >
        <Box component="span" variant="contained" sx={style.deleteTitle}>
          Delete Confirmation
        </Box>
        <Box>
          <RiCloseFill
            onClick={handleDeleteCloseConfirmModal}
            size="22"
            cursor="pointer"
          />
        </Box>
      </Grid>
      <Divider sx={{ backgroundColor: "#D2D2D2" }} />
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <Divider sx={{ backgroundColor: "#D2D2D2" }} />
      <DialogActions sx={{ mt: 1, mb: 2, paddingRight: "20px" }}>
        <Button
          sx={style.cancelBtn}
          variant="contained"
          onClick={handleDeleteCloseConfirmModal}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeleteApiCall}
          disabled={submitLoading}
          sx={style.deleteBtn}
          variant="contained"
        >
          {submitLoading && (
            <CircularProgress
              size={16}
              sx={{ color: "#ffff", marginRight: "5px" }}
            />
          )}
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDeleteModal;
