/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createReviewOption = /* GraphQL */ `
  mutation CreateReviewOption(
    $input: CreateReviewOptionInput!
    $condition: ModelReviewOptionConditionInput
  ) {
    createReviewOption(input: $input, condition: $condition) {
      id
      label
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReviewOption = /* GraphQL */ `
  mutation UpdateReviewOption(
    $input: UpdateReviewOptionInput!
    $condition: ModelReviewOptionConditionInput
  ) {
    updateReviewOption(input: $input, condition: $condition) {
      id
      label
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReviewOption = /* GraphQL */ `
  mutation DeleteReviewOption(
    $input: DeleteReviewOptionInput!
    $condition: ModelReviewOptionConditionInput
  ) {
    deleteReviewOption(input: $input, condition: $condition) {
      id
      label
      slug
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      userID
      type
      ReviewOption {
        id
        label
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rating
      description
      Pet {
        id
        name
        username
        type
        breed
        gender
        dob
        status
        rating
        reviews
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petProfileImgId
        petCoverPhotoId
        petLocationId
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reviewReviewOptionId
      reviewPetId
      reviewBusinessId
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      userID
      type
      ReviewOption {
        id
        label
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rating
      description
      Pet {
        id
        name
        username
        type
        breed
        gender
        dob
        status
        rating
        reviews
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petProfileImgId
        petCoverPhotoId
        petLocationId
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reviewReviewOptionId
      reviewPetId
      reviewBusinessId
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      userID
      type
      ReviewOption {
        id
        label
        slug
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      rating
      description
      Pet {
        id
        name
        username
        type
        breed
        gender
        dob
        status
        rating
        reviews
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        petProfileImgId
        petCoverPhotoId
        petLocationId
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      reviewReviewOptionId
      reviewPetId
      reviewBusinessId
      __typename
    }
  }
`;
export const createClaim = /* GraphQL */ `
  mutation CreateClaim(
    $input: CreateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    createClaim(input: $input, condition: $condition) {
      id
      firstname
      lastname
      phone
      email
      description
      status
      assetType
      assetID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClaim = /* GraphQL */ `
  mutation UpdateClaim(
    $input: UpdateClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    updateClaim(input: $input, condition: $condition) {
      id
      firstname
      lastname
      phone
      email
      description
      status
      assetType
      assetID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClaim = /* GraphQL */ `
  mutation DeleteClaim(
    $input: DeleteClaimInput!
    $condition: ModelClaimConditionInput
  ) {
    deleteClaim(input: $input, condition: $condition) {
      id
      firstname
      lastname
      phone
      email
      description
      status
      assetType
      assetID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFollow = /* GraphQL */ `
  mutation CreateFollow(
    $input: CreateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    createFollow(input: $input, condition: $condition) {
      id
      status
      FollowID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFollow = /* GraphQL */ `
  mutation UpdateFollow(
    $input: UpdateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    updateFollow(input: $input, condition: $condition) {
      id
      status
      FollowID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFollow = /* GraphQL */ `
  mutation DeleteFollow(
    $input: DeleteFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    deleteFollow(input: $input, condition: $condition) {
      id
      status
      FollowID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      assetID
      type
      assetType
      description
      reason
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      assetID
      type
      assetType
      description
      reason
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      assetID
      type
      assetType
      description
      reason
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPollResponse = /* GraphQL */ `
  mutation CreatePollResponse(
    $input: CreatePollResponseInput!
    $condition: ModelPollResponseConditionInput
  ) {
    createPollResponse(input: $input, condition: $condition) {
      id
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      pollResponseUserId
      __typename
    }
  }
`;
export const updatePollResponse = /* GraphQL */ `
  mutation UpdatePollResponse(
    $input: UpdatePollResponseInput!
    $condition: ModelPollResponseConditionInput
  ) {
    updatePollResponse(input: $input, condition: $condition) {
      id
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      pollResponseUserId
      __typename
    }
  }
`;
export const deletePollResponse = /* GraphQL */ `
  mutation DeletePollResponse(
    $input: DeletePollResponseInput!
    $condition: ModelPollResponseConditionInput
  ) {
    deletePollResponse(input: $input, condition: $condition) {
      id
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      pollResponseUserId
      __typename
    }
  }
`;
export const createPoll = /* GraphQL */ `
  mutation CreatePoll(
    $input: CreatePollInput!
    $condition: ModelPollConditionInput
  ) {
    createPoll(input: $input, condition: $condition) {
      id
      label
      description
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePoll = /* GraphQL */ `
  mutation UpdatePoll(
    $input: UpdatePollInput!
    $condition: ModelPollConditionInput
  ) {
    updatePoll(input: $input, condition: $condition) {
      id
      label
      description
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePoll = /* GraphQL */ `
  mutation DeletePoll(
    $input: DeletePollInput!
    $condition: ModelPollConditionInput
  ) {
    deletePoll(input: $input, condition: $condition) {
      id
      label
      description
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWelcome = /* GraphQL */ `
  mutation CreateWelcome(
    $input: CreateWelcomeInput!
    $condition: ModelWelcomeConditionInput
  ) {
    createWelcome(input: $input, condition: $condition) {
      id
      value
      feedID
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      welcomeUserId
      __typename
    }
  }
`;
export const updateWelcome = /* GraphQL */ `
  mutation UpdateWelcome(
    $input: UpdateWelcomeInput!
    $condition: ModelWelcomeConditionInput
  ) {
    updateWelcome(input: $input, condition: $condition) {
      id
      value
      feedID
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      welcomeUserId
      __typename
    }
  }
`;
export const deleteWelcome = /* GraphQL */ `
  mutation DeleteWelcome(
    $input: DeleteWelcomeInput!
    $condition: ModelWelcomeConditionInput
  ) {
    deleteWelcome(input: $input, condition: $condition) {
      id
      value
      feedID
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      welcomeUserId
      __typename
    }
  }
`;
export const createFriendShip = /* GraphQL */ `
  mutation CreateFriendShip(
    $input: CreateFriendShipInput!
    $condition: ModelFriendShipConditionInput
  ) {
    createFriendShip(input: $input, condition: $condition) {
      id
      status
      description
      senderID
      Users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFriendShip = /* GraphQL */ `
  mutation UpdateFriendShip(
    $input: UpdateFriendShipInput!
    $condition: ModelFriendShipConditionInput
  ) {
    updateFriendShip(input: $input, condition: $condition) {
      id
      status
      description
      senderID
      Users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFriendShip = /* GraphQL */ `
  mutation DeleteFriendShip(
    $input: DeleteFriendShipInput!
    $condition: ModelFriendShipConditionInput
  ) {
    deleteFriendShip(input: $input, condition: $condition) {
      id
      status
      description
      senderID
      Users {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMedia = /* GraphQL */ `
  mutation CreateMedia(
    $input: CreateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    createMedia(input: $input, condition: $condition) {
      id
      type
      description
      bucket
      region
      key
      url
      messageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMedia = /* GraphQL */ `
  mutation UpdateMedia(
    $input: UpdateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    updateMedia(input: $input, condition: $condition) {
      id
      type
      description
      bucket
      region
      key
      url
      messageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMedia = /* GraphQL */ `
  mutation DeleteMedia(
    $input: DeleteMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    deleteMedia(input: $input, condition: $condition) {
      id
      type
      description
      bucket
      region
      key
      url
      messageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUpload = /* GraphQL */ `
  mutation CreateUpload(
    $input: CreateUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    createUpload(input: $input, condition: $condition) {
      id
      type
      description
      bucket
      region
      key
      url
      assetType
      assetID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUpload = /* GraphQL */ `
  mutation UpdateUpload(
    $input: UpdateUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    updateUpload(input: $input, condition: $condition) {
      id
      type
      description
      bucket
      region
      key
      url
      assetType
      assetID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUpload = /* GraphQL */ `
  mutation DeleteUpload(
    $input: DeleteUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    deleteUpload(input: $input, condition: $condition) {
      id
      type
      description
      bucket
      region
      key
      url
      assetType
      assetID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      status
      userID
      chatroomID
      Media {
        nextToken
        startedAt
        __typename
      }
      type
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      status
      userID
      chatroomID
      Media {
        nextToken
        startedAt
        __typename
      }
      type
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      status
      userID
      chatroomID
      Media {
        nextToken
        startedAt
        __typename
      }
      type
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatRoomUser = /* GraphQL */ `
  mutation CreateChatRoomUser(
    $input: CreateChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    createChatRoomUser(input: $input, condition: $condition) {
      id
      chatrooms {
        nextToken
        startedAt
        __typename
      }
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomUserUserId
      __typename
    }
  }
`;
export const updateChatRoomUser = /* GraphQL */ `
  mutation UpdateChatRoomUser(
    $input: UpdateChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    updateChatRoomUser(input: $input, condition: $condition) {
      id
      chatrooms {
        nextToken
        startedAt
        __typename
      }
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomUserUserId
      __typename
    }
  }
`;
export const deleteChatRoomUser = /* GraphQL */ `
  mutation DeleteChatRoomUser(
    $input: DeleteChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    deleteChatRoomUser(input: $input, condition: $condition) {
      id
      chatrooms {
        nextToken
        startedAt
        __typename
      }
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomUserUserId
      __typename
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      ChatRoomUsers {
        nextToken
        startedAt
        __typename
      }
      Messages {
        nextToken
        startedAt
        __typename
      }
      lastMessage {
        id
        createdAt
        content
        status
        userID
        chatroomID
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomLastMessageId
      __typename
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      ChatRoomUsers {
        nextToken
        startedAt
        __typename
      }
      Messages {
        nextToken
        startedAt
        __typename
      }
      lastMessage {
        id
        createdAt
        content
        status
        userID
        chatroomID
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomLastMessageId
      __typename
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      ChatRoomUsers {
        nextToken
        startedAt
        __typename
      }
      Messages {
        nextToken
        startedAt
        __typename
      }
      lastMessage {
        id
        createdAt
        content
        status
        userID
        chatroomID
        type
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      chatRoomLastMessageId
      __typename
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      title
      excerpt
      description
      type
      category
      employmentStatus
      companyName
      url
      deadline
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CompanyLogo {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      JobFields {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobLocationId
      jobImageId
      jobCompanyLogoId
      __typename
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      title
      excerpt
      description
      type
      category
      employmentStatus
      companyName
      url
      deadline
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CompanyLogo {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      JobFields {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobLocationId
      jobImageId
      jobCompanyLogoId
      __typename
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      title
      excerpt
      description
      type
      category
      employmentStatus
      companyName
      url
      deadline
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      CompanyLogo {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      JobFields {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobLocationId
      jobImageId
      jobCompanyLogoId
      __typename
    }
  }
`;
export const createJobField = /* GraphQL */ `
  mutation CreateJobField(
    $input: CreateJobFieldInput!
    $condition: ModelJobFieldConditionInput
  ) {
    createJobField(input: $input, condition: $condition) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      jobID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobFieldProfileFieldId
      __typename
    }
  }
`;
export const updateJobField = /* GraphQL */ `
  mutation UpdateJobField(
    $input: UpdateJobFieldInput!
    $condition: ModelJobFieldConditionInput
  ) {
    updateJobField(input: $input, condition: $condition) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      jobID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobFieldProfileFieldId
      __typename
    }
  }
`;
export const deleteJobField = /* GraphQL */ `
  mutation DeleteJobField(
    $input: DeleteJobFieldInput!
    $condition: ModelJobFieldConditionInput
  ) {
    deleteJobField(input: $input, condition: $condition) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      jobID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobFieldProfileFieldId
      __typename
    }
  }
`;
export const createDeal = /* GraphQL */ `
  mutation CreateDeal(
    $input: CreateDealInput!
    $condition: ModelDealConditionInput
  ) {
    createDeal(input: $input, condition: $condition) {
      id
      title
      excerpt
      type
      amount
      percentage
      slug
      description
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dealLocationId
      dealImageId
      dealBusinessId
      __typename
    }
  }
`;
export const updateDeal = /* GraphQL */ `
  mutation UpdateDeal(
    $input: UpdateDealInput!
    $condition: ModelDealConditionInput
  ) {
    updateDeal(input: $input, condition: $condition) {
      id
      title
      excerpt
      type
      amount
      percentage
      slug
      description
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dealLocationId
      dealImageId
      dealBusinessId
      __typename
    }
  }
`;
export const deleteDeal = /* GraphQL */ `
  mutation DeleteDeal(
    $input: DeleteDealInput!
    $condition: ModelDealConditionInput
  ) {
    deleteDeal(input: $input, condition: $condition) {
      id
      title
      excerpt
      type
      amount
      percentage
      slug
      description
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dealLocationId
      dealImageId
      dealBusinessId
      __typename
    }
  }
`;
export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      id
      title
      excerpt
      type
      category
      source
      url
      imageUrl
      description
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      newsLocationId
      newsImageId
      __typename
    }
  }
`;
export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      id
      title
      excerpt
      type
      category
      source
      url
      imageUrl
      description
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      newsLocationId
      newsImageId
      __typename
    }
  }
`;
export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      id
      title
      excerpt
      type
      category
      source
      url
      imageUrl
      description
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      newsLocationId
      newsImageId
      __typename
    }
  }
`;
export const createBlockUser = /* GraphQL */ `
  mutation CreateBlockUser(
    $input: CreateBlockUserInput!
    $condition: ModelBlockUserConditionInput
  ) {
    createBlockUser(input: $input, condition: $condition) {
      id
      reason
      description
      userID
      blockID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBlockUser = /* GraphQL */ `
  mutation UpdateBlockUser(
    $input: UpdateBlockUserInput!
    $condition: ModelBlockUserConditionInput
  ) {
    updateBlockUser(input: $input, condition: $condition) {
      id
      reason
      description
      userID
      blockID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBlockUser = /* GraphQL */ `
  mutation DeleteBlockUser(
    $input: DeleteBlockUserInput!
    $condition: ModelBlockUserConditionInput
  ) {
    deleteBlockUser(input: $input, condition: $condition) {
      id
      reason
      description
      userID
      blockID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      startDate
      startTime
      endDate
      endTime
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      EventCategories {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventLocationId
      eventImageId
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      startDate
      startTime
      endDate
      endTime
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      EventCategories {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventLocationId
      eventImageId
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      startDate
      startTime
      endDate
      endTime
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      EventCategories {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventLocationId
      eventImageId
      __typename
    }
  }
`;
export const createEventCategory = /* GraphQL */ `
  mutation CreateEventCategory(
    $input: CreateEventCategoryInput!
    $condition: ModelEventCategoryConditionInput
  ) {
    createEventCategory(input: $input, condition: $condition) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      eventID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventCategoryCategoryId
      __typename
    }
  }
`;
export const updateEventCategory = /* GraphQL */ `
  mutation UpdateEventCategory(
    $input: UpdateEventCategoryInput!
    $condition: ModelEventCategoryConditionInput
  ) {
    updateEventCategory(input: $input, condition: $condition) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      eventID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventCategoryCategoryId
      __typename
    }
  }
`;
export const deleteEventCategory = /* GraphQL */ `
  mutation DeleteEventCategory(
    $input: DeleteEventCategoryInput!
    $condition: ModelEventCategoryConditionInput
  ) {
    deleteEventCategory(input: $input, condition: $condition) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      eventID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      eventCategoryCategoryId
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      status
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      GroupSetting {
        id
        invite
        post
        photo
        video
        document
        message
        notification
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      GroupMembers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      groupLocationId
      groupImageId
      groupBusinessId
      groupGroupSettingId
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      status
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      GroupSetting {
        id
        invite
        post
        photo
        video
        document
        message
        notification
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      GroupMembers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      groupLocationId
      groupImageId
      groupBusinessId
      groupGroupSettingId
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      status
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Business {
        id
        title
        type
        slug
        description
        userID
        status
        rating
        reviews
        claimed
        deeplink
        table
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        businessLocationId
        businessProfileImgId
        businessCoverPhotoId
        __typename
      }
      GroupSetting {
        id
        invite
        post
        photo
        video
        document
        message
        notification
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      GroupMembers {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      groupLocationId
      groupImageId
      groupBusinessId
      groupGroupSettingId
      __typename
    }
  }
`;
export const createGroupSetting = /* GraphQL */ `
  mutation CreateGroupSetting(
    $input: CreateGroupSettingInput!
    $condition: ModelGroupSettingConditionInput
  ) {
    createGroupSetting(input: $input, condition: $condition) {
      id
      invite
      post
      photo
      video
      document
      message
      notification
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGroupSetting = /* GraphQL */ `
  mutation UpdateGroupSetting(
    $input: UpdateGroupSettingInput!
    $condition: ModelGroupSettingConditionInput
  ) {
    updateGroupSetting(input: $input, condition: $condition) {
      id
      invite
      post
      photo
      video
      document
      message
      notification
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGroupSetting = /* GraphQL */ `
  mutation DeleteGroupSetting(
    $input: DeleteGroupSettingInput!
    $condition: ModelGroupSettingConditionInput
  ) {
    deleteGroupSetting(input: $input, condition: $condition) {
      id
      invite
      post
      photo
      video
      document
      message
      notification
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGroupMember = /* GraphQL */ `
  mutation CreateGroupMember(
    $input: CreateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    createGroupMember(input: $input, condition: $condition) {
      id
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      role
      status
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      groupMemberUserId
      __typename
    }
  }
`;
export const updateGroupMember = /* GraphQL */ `
  mutation UpdateGroupMember(
    $input: UpdateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    updateGroupMember(input: $input, condition: $condition) {
      id
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      role
      status
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      groupMemberUserId
      __typename
    }
  }
`;
export const deleteGroupMember = /* GraphQL */ `
  mutation DeleteGroupMember(
    $input: DeleteGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    deleteGroupMember(input: $input, condition: $condition) {
      id
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      role
      status
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      groupMemberUserId
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      price
      sale
      free
      area
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      productImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ProductImages {
        nextToken
        startedAt
        __typename
      }
      ProductCategories {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productLocationId
      productProductImgId
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      price
      sale
      free
      area
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      productImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ProductImages {
        nextToken
        startedAt
        __typename
      }
      ProductCategories {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productLocationId
      productProductImgId
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      price
      sale
      free
      area
      status
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      productImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ProductImages {
        nextToken
        startedAt
        __typename
      }
      ProductCategories {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productLocationId
      productProductImgId
      __typename
    }
  }
`;
export const createProductImage = /* GraphQL */ `
  mutation CreateProductImage(
    $input: CreateProductImageInput!
    $condition: ModelProductImageConditionInput
  ) {
    createProductImage(input: $input, condition: $condition) {
      id
      title
      description
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productImageImageId
      __typename
    }
  }
`;
export const updateProductImage = /* GraphQL */ `
  mutation UpdateProductImage(
    $input: UpdateProductImageInput!
    $condition: ModelProductImageConditionInput
  ) {
    updateProductImage(input: $input, condition: $condition) {
      id
      title
      description
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productImageImageId
      __typename
    }
  }
`;
export const deleteProductImage = /* GraphQL */ `
  mutation DeleteProductImage(
    $input: DeleteProductImageInput!
    $condition: ModelProductImageConditionInput
  ) {
    deleteProductImage(input: $input, condition: $condition) {
      id
      title
      description
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productImageImageId
      __typename
    }
  }
`;
export const createProductCategory = /* GraphQL */ `
  mutation CreateProductCategory(
    $input: CreateProductCategoryInput!
    $condition: ModelProductCategoryConditionInput
  ) {
    createProductCategory(input: $input, condition: $condition) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productCategoryCategoryId
      __typename
    }
  }
`;
export const updateProductCategory = /* GraphQL */ `
  mutation UpdateProductCategory(
    $input: UpdateProductCategoryInput!
    $condition: ModelProductCategoryConditionInput
  ) {
    updateProductCategory(input: $input, condition: $condition) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productCategoryCategoryId
      __typename
    }
  }
`;
export const deleteProductCategory = /* GraphQL */ `
  mutation DeleteProductCategory(
    $input: DeleteProductCategoryInput!
    $condition: ModelProductCategoryConditionInput
  ) {
    deleteProductCategory(input: $input, condition: $condition) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      productID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productCategoryCategoryId
      __typename
    }
  }
`;
export const createBusinessCategory = /* GraphQL */ `
  mutation CreateBusinessCategory(
    $input: CreateBusinessCategoryInput!
    $condition: ModelBusinessCategoryConditionInput
  ) {
    createBusinessCategory(input: $input, condition: $condition) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessCategoryCategoryId
      __typename
    }
  }
`;
export const updateBusinessCategory = /* GraphQL */ `
  mutation UpdateBusinessCategory(
    $input: UpdateBusinessCategoryInput!
    $condition: ModelBusinessCategoryConditionInput
  ) {
    updateBusinessCategory(input: $input, condition: $condition) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessCategoryCategoryId
      __typename
    }
  }
`;
export const deleteBusinessCategory = /* GraphQL */ `
  mutation DeleteBusinessCategory(
    $input: DeleteBusinessCategoryInput!
    $condition: ModelBusinessCategoryConditionInput
  ) {
    deleteBusinessCategory(input: $input, condition: $condition) {
      id
      description
      Category {
        id
        name
        slug
        type
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        categoryLogoId
        categoryCoverId
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessCategoryCategoryId
      __typename
    }
  }
`;
export const createBusinessImage = /* GraphQL */ `
  mutation CreateBusinessImage(
    $input: CreateBusinessImageInput!
    $condition: ModelBusinessImageConditionInput
  ) {
    createBusinessImage(input: $input, condition: $condition) {
      id
      title
      descripti
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessImageImageId
      __typename
    }
  }
`;
export const updateBusinessImage = /* GraphQL */ `
  mutation UpdateBusinessImage(
    $input: UpdateBusinessImageInput!
    $condition: ModelBusinessImageConditionInput
  ) {
    updateBusinessImage(input: $input, condition: $condition) {
      id
      title
      descripti
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessImageImageId
      __typename
    }
  }
`;
export const deleteBusinessImage = /* GraphQL */ `
  mutation DeleteBusinessImage(
    $input: DeleteBusinessImageInput!
    $condition: ModelBusinessImageConditionInput
  ) {
    deleteBusinessImage(input: $input, condition: $condition) {
      id
      title
      descripti
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessImageImageId
      __typename
    }
  }
`;
export const createBusinessField = /* GraphQL */ `
  mutation CreateBusinessField(
    $input: CreateBusinessFieldInput!
    $condition: ModelBusinessFieldConditionInput
  ) {
    createBusinessField(input: $input, condition: $condition) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessFieldProfileFieldId
      __typename
    }
  }
`;
export const updateBusinessField = /* GraphQL */ `
  mutation UpdateBusinessField(
    $input: UpdateBusinessFieldInput!
    $condition: ModelBusinessFieldConditionInput
  ) {
    updateBusinessField(input: $input, condition: $condition) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessFieldProfileFieldId
      __typename
    }
  }
`;
export const deleteBusinessField = /* GraphQL */ `
  mutation DeleteBusinessField(
    $input: DeleteBusinessFieldInput!
    $condition: ModelBusinessFieldConditionInput
  ) {
    deleteBusinessField(input: $input, condition: $condition) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      businessID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessFieldProfileFieldId
      __typename
    }
  }
`;
export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      coverPhoto {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      rating
      reviews
      claimed
      deeplink
      BusinessImages {
        nextToken
        startedAt
        __typename
      }
      BusinessCategories {
        nextToken
        startedAt
        __typename
      }
      BusinessFields {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessLocationId
      businessProfileImgId
      businessCoverPhotoId
      __typename
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      coverPhoto {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      rating
      reviews
      claimed
      deeplink
      BusinessImages {
        nextToken
        startedAt
        __typename
      }
      BusinessCategories {
        nextToken
        startedAt
        __typename
      }
      BusinessFields {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessLocationId
      businessProfileImgId
      businessCoverPhotoId
      __typename
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      title
      type
      slug
      description
      userID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      profileImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      coverPhoto {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      rating
      reviews
      claimed
      deeplink
      BusinessImages {
        nextToken
        startedAt
        __typename
      }
      BusinessCategories {
        nextToken
        startedAt
        __typename
      }
      BusinessFields {
        nextToken
        startedAt
        __typename
      }
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      businessLocationId
      businessProfileImgId
      businessCoverPhotoId
      __typename
    }
  }
`;
export const createPetImage = /* GraphQL */ `
  mutation CreatePetImage(
    $input: CreatePetImageInput!
    $condition: ModelPetImageConditionInput
  ) {
    createPetImage(input: $input, condition: $condition) {
      id
      title
      description
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      petID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petImageImageId
      __typename
    }
  }
`;
export const updatePetImage = /* GraphQL */ `
  mutation UpdatePetImage(
    $input: UpdatePetImageInput!
    $condition: ModelPetImageConditionInput
  ) {
    updatePetImage(input: $input, condition: $condition) {
      id
      title
      description
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      petID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petImageImageId
      __typename
    }
  }
`;
export const deletePetImage = /* GraphQL */ `
  mutation DeletePetImage(
    $input: DeletePetImageInput!
    $condition: ModelPetImageConditionInput
  ) {
    deletePetImage(input: $input, condition: $condition) {
      id
      title
      description
      Image {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      petID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petImageImageId
      __typename
    }
  }
`;
export const createPetField = /* GraphQL */ `
  mutation CreatePetField(
    $input: CreatePetFieldInput!
    $condition: ModelPetFieldConditionInput
  ) {
    createPetField(input: $input, condition: $condition) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      petID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petFieldProfileFieldId
      __typename
    }
  }
`;
export const updatePetField = /* GraphQL */ `
  mutation UpdatePetField(
    $input: UpdatePetFieldInput!
    $condition: ModelPetFieldConditionInput
  ) {
    updatePetField(input: $input, condition: $condition) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      petID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petFieldProfileFieldId
      __typename
    }
  }
`;
export const deletePetField = /* GraphQL */ `
  mutation DeletePetField(
    $input: DeletePetFieldInput!
    $condition: ModelPetFieldConditionInput
  ) {
    deletePetField(input: $input, condition: $condition) {
      id
      value
      privacy
      ProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      petID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petFieldProfileFieldId
      __typename
    }
  }
`;
export const createPet = /* GraphQL */ `
  mutation CreatePet(
    $input: CreatePetInput!
    $condition: ModelPetConditionInput
  ) {
    createPet(input: $input, condition: $condition) {
      id
      name
      username
      type
      breed
      gender
      dob
      status
      rating
      reviews
      userID
      profileImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      coverPhoto {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      PetImages {
        nextToken
        startedAt
        __typename
      }
      PetFields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petProfileImgId
      petCoverPhotoId
      petLocationId
      __typename
    }
  }
`;
export const updatePet = /* GraphQL */ `
  mutation UpdatePet(
    $input: UpdatePetInput!
    $condition: ModelPetConditionInput
  ) {
    updatePet(input: $input, condition: $condition) {
      id
      name
      username
      type
      breed
      gender
      dob
      status
      rating
      reviews
      userID
      profileImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      coverPhoto {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      PetImages {
        nextToken
        startedAt
        __typename
      }
      PetFields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petProfileImgId
      petCoverPhotoId
      petLocationId
      __typename
    }
  }
`;
export const deletePet = /* GraphQL */ `
  mutation DeletePet(
    $input: DeletePetInput!
    $condition: ModelPetConditionInput
  ) {
    deletePet(input: $input, condition: $condition) {
      id
      name
      username
      type
      breed
      gender
      dob
      status
      rating
      reviews
      userID
      profileImg {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      coverPhoto {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      PetImages {
        nextToken
        startedAt
        __typename
      }
      PetFields {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      petProfileImgId
      petCoverPhotoId
      petLocationId
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      slug
      type
      description
      logo {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cover {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      categoryLogoId
      categoryCoverId
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      slug
      type
      description
      logo {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cover {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      categoryLogoId
      categoryCoverId
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      slug
      type
      description
      logo {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      cover {
        id
        url
        type
        description
        bucket
        region
        key
        feedID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      categoryLogoId
      categoryCoverId
      __typename
    }
  }
`;
export const createUserOption = /* GraphQL */ `
  mutation CreateUserOption(
    $input: CreateUserOptionInput!
    $condition: ModelUserOptionConditionInput
  ) {
    createUserOption(input: $input, condition: $condition) {
      id
      value
      Option {
        id
        slug
        description
        title
        options
        instruction
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userOptionOptionId
      __typename
    }
  }
`;
export const updateUserOption = /* GraphQL */ `
  mutation UpdateUserOption(
    $input: UpdateUserOptionInput!
    $condition: ModelUserOptionConditionInput
  ) {
    updateUserOption(input: $input, condition: $condition) {
      id
      value
      Option {
        id
        slug
        description
        title
        options
        instruction
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userOptionOptionId
      __typename
    }
  }
`;
export const deleteUserOption = /* GraphQL */ `
  mutation DeleteUserOption(
    $input: DeleteUserOptionInput!
    $condition: ModelUserOptionConditionInput
  ) {
    deleteUserOption(input: $input, condition: $condition) {
      id
      value
      Option {
        id
        slug
        description
        title
        options
        instruction
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userOptionOptionId
      __typename
    }
  }
`;
export const createOption = /* GraphQL */ `
  mutation CreateOption(
    $input: CreateOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    createOption(input: $input, condition: $condition) {
      id
      slug
      description
      title
      options
      instruction
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOption = /* GraphQL */ `
  mutation UpdateOption(
    $input: UpdateOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    updateOption(input: $input, condition: $condition) {
      id
      slug
      description
      title
      options
      instruction
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOption = /* GraphQL */ `
  mutation DeleteOption(
    $input: DeleteOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    deleteOption(input: $input, condition: $condition) {
      id
      slug
      description
      title
      options
      instruction
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserAddress = /* GraphQL */ `
  mutation CreateUserAddress(
    $input: CreateUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    createUserAddress(input: $input, condition: $condition) {
      id
      title
      description
      instruction
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAddressLocationId
      __typename
    }
  }
`;
export const updateUserAddress = /* GraphQL */ `
  mutation UpdateUserAddress(
    $input: UpdateUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    updateUserAddress(input: $input, condition: $condition) {
      id
      title
      description
      instruction
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAddressLocationId
      __typename
    }
  }
`;
export const deleteUserAddress = /* GraphQL */ `
  mutation DeleteUserAddress(
    $input: DeleteUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    deleteUserAddress(input: $input, condition: $condition) {
      id
      title
      description
      instruction
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userAddressLocationId
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      address
      city
      province
      zip
      lat
      lng
      country
      instruction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      address
      city
      province
      zip
      lat
      lng
      country
      instruction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      address
      city
      province
      zip
      lat
      lng
      country
      instruction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createView = /* GraphQL */ `
  mutation CreateView(
    $input: CreateViewInput!
    $condition: ModelViewConditionInput
  ) {
    createView(input: $input, condition: $condition) {
      id
      type
      decription
      feedID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateView = /* GraphQL */ `
  mutation UpdateView(
    $input: UpdateViewInput!
    $condition: ModelViewConditionInput
  ) {
    updateView(input: $input, condition: $condition) {
      id
      type
      decription
      feedID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteView = /* GraphQL */ `
  mutation DeleteView(
    $input: DeleteViewInput!
    $condition: ModelViewConditionInput
  ) {
    deleteView(input: $input, condition: $condition) {
      id
      type
      decription
      feedID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createShare = /* GraphQL */ `
  mutation CreateShare(
    $input: CreateShareInput!
    $condition: ModelShareConditionInput
  ) {
    createShare(input: $input, condition: $condition) {
      id
      type
      feedID
      description
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateShare = /* GraphQL */ `
  mutation UpdateShare(
    $input: UpdateShareInput!
    $condition: ModelShareConditionInput
  ) {
    updateShare(input: $input, condition: $condition) {
      id
      type
      feedID
      description
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteShare = /* GraphQL */ `
  mutation DeleteShare(
    $input: DeleteShareInput!
    $condition: ModelShareConditionInput
  ) {
    deleteShare(input: $input, condition: $condition) {
      id
      type
      feedID
      description
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      type
      feedID
      description
      userID
      feedcommentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      type
      feedID
      description
      userID
      feedcommentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      type
      feedID
      description
      userID
      feedcommentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      url
      type
      description
      bucket
      region
      key
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      url
      type
      description
      bucket
      region
      key
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      url
      type
      description
      bucket
      region
      key
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReel = /* GraphQL */ `
  mutation CreateReel(
    $input: CreateReelInput!
    $condition: ModelReelConditionInput
  ) {
    createReel(input: $input, condition: $condition) {
      id
      url
      type
      description
      bucket
      region
      key
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReel = /* GraphQL */ `
  mutation UpdateReel(
    $input: UpdateReelInput!
    $condition: ModelReelConditionInput
  ) {
    updateReel(input: $input, condition: $condition) {
      id
      url
      type
      description
      bucket
      region
      key
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReel = /* GraphQL */ `
  mutation DeleteReel(
    $input: DeleteReelInput!
    $condition: ModelReelConditionInput
  ) {
    deleteReel(input: $input, condition: $condition) {
      id
      url
      type
      description
      bucket
      region
      key
      feedID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFriend = /* GraphQL */ `
  mutation CreateFriend(
    $input: CreateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    createFriend(input: $input, condition: $condition) {
      id
      memberID
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      friendUserId
      __typename
    }
  }
`;
export const updateFriend = /* GraphQL */ `
  mutation UpdateFriend(
    $input: UpdateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    updateFriend(input: $input, condition: $condition) {
      id
      memberID
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      friendUserId
      __typename
    }
  }
`;
export const deleteFriend = /* GraphQL */ `
  mutation DeleteFriend(
    $input: DeleteFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    deleteFriend(input: $input, condition: $condition) {
      id
      memberID
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      friendUserId
      __typename
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      bio
      status
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Friends {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      memberUserId
      __typename
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      bio
      status
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Friends {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      memberUserId
      __typename
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      bio
      status
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Friends {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      memberUserId
      __typename
    }
  }
`;
export const createFeedComment = /* GraphQL */ `
  mutation CreateFeedComment(
    $input: CreateFeedCommentInput!
    $condition: ModelFeedCommentConditionInput
  ) {
    createFeedComment(input: $input, condition: $condition) {
      id
      content
      parent
      feedID
      Likes {
        nextToken
        startedAt
        __typename
      }
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      feedCommentLocationId
      feedCommentUserId
      __typename
    }
  }
`;
export const updateFeedComment = /* GraphQL */ `
  mutation UpdateFeedComment(
    $input: UpdateFeedCommentInput!
    $condition: ModelFeedCommentConditionInput
  ) {
    updateFeedComment(input: $input, condition: $condition) {
      id
      content
      parent
      feedID
      Likes {
        nextToken
        startedAt
        __typename
      }
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      feedCommentLocationId
      feedCommentUserId
      __typename
    }
  }
`;
export const deleteFeedComment = /* GraphQL */ `
  mutation DeleteFeedComment(
    $input: DeleteFeedCommentInput!
    $condition: ModelFeedCommentConditionInput
  ) {
    deleteFeedComment(input: $input, condition: $condition) {
      id
      content
      parent
      feedID
      Likes {
        nextToken
        startedAt
        __typename
      }
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      feedCommentLocationId
      feedCommentUserId
      __typename
    }
  }
`;
export const createFeed = /* GraphQL */ `
  mutation CreateFeed(
    $input: CreateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    createFeed(input: $input, condition: $condition) {
      id
      content
      userID
      type
      privacy
      deeplink
      FeedComments {
        nextToken
        startedAt
        __typename
      }
      Images {
        nextToken
        startedAt
        __typename
      }
      Reels {
        nextToken
        startedAt
        __typename
      }
      Shares {
        nextToken
        startedAt
        __typename
      }
      Likes {
        nextToken
        startedAt
        __typename
      }
      Views {
        nextToken
        startedAt
        __typename
      }
      Welcomes {
        nextToken
        startedAt
        __typename
      }
      Polls {
        nextToken
        startedAt
        __typename
      }
      sharedAssetID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      feedLocationId
      __typename
    }
  }
`;
export const updateFeed = /* GraphQL */ `
  mutation UpdateFeed(
    $input: UpdateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    updateFeed(input: $input, condition: $condition) {
      id
      content
      userID
      type
      privacy
      deeplink
      FeedComments {
        nextToken
        startedAt
        __typename
      }
      Images {
        nextToken
        startedAt
        __typename
      }
      Reels {
        nextToken
        startedAt
        __typename
      }
      Shares {
        nextToken
        startedAt
        __typename
      }
      Likes {
        nextToken
        startedAt
        __typename
      }
      Views {
        nextToken
        startedAt
        __typename
      }
      Welcomes {
        nextToken
        startedAt
        __typename
      }
      Polls {
        nextToken
        startedAt
        __typename
      }
      sharedAssetID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      feedLocationId
      __typename
    }
  }
`;
export const deleteFeed = /* GraphQL */ `
  mutation DeleteFeed(
    $input: DeleteFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    deleteFeed(input: $input, condition: $condition) {
      id
      content
      userID
      type
      privacy
      deeplink
      FeedComments {
        nextToken
        startedAt
        __typename
      }
      Images {
        nextToken
        startedAt
        __typename
      }
      Reels {
        nextToken
        startedAt
        __typename
      }
      Shares {
        nextToken
        startedAt
        __typename
      }
      Likes {
        nextToken
        startedAt
        __typename
      }
      Views {
        nextToken
        startedAt
        __typename
      }
      Welcomes {
        nextToken
        startedAt
        __typename
      }
      Polls {
        nextToken
        startedAt
        __typename
      }
      sharedAssetID
      Location {
        id
        address
        city
        province
        zip
        lat
        lng
        country
        instruction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      table
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      feedLocationId
      __typename
    }
  }
`;
export const createUserField = /* GraphQL */ `
  mutation CreateUserField(
    $input: CreateUserFieldInput!
    $condition: ModelUserFieldConditionInput
  ) {
    createUserField(input: $input, condition: $condition) {
      id
      value
      privacy
      userID
      UserProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userFieldUserProfileFieldId
      __typename
    }
  }
`;
export const updateUserField = /* GraphQL */ `
  mutation UpdateUserField(
    $input: UpdateUserFieldInput!
    $condition: ModelUserFieldConditionInput
  ) {
    updateUserField(input: $input, condition: $condition) {
      id
      value
      privacy
      userID
      UserProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userFieldUserProfileFieldId
      __typename
    }
  }
`;
export const deleteUserField = /* GraphQL */ `
  mutation DeleteUserField(
    $input: DeleteUserFieldInput!
    $condition: ModelUserFieldConditionInput
  ) {
    deleteUserField(input: $input, condition: $condition) {
      id
      value
      privacy
      userID
      UserProfileField {
        id
        title
        description
        placeholder
        slug
        instruction
        type
        visibility
        profileType
        profileGroup
        position
        status
        required
        fieldOptions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userFieldUserProfileFieldId
      __typename
    }
  }
`;
export const createUserProfileField = /* GraphQL */ `
  mutation CreateUserProfileField(
    $input: CreateUserProfileFieldInput!
    $condition: ModelUserProfileFieldConditionInput
  ) {
    createUserProfileField(input: $input, condition: $condition) {
      id
      title
      description
      placeholder
      slug
      instruction
      type
      visibility
      profileType
      profileGroup
      position
      status
      required
      fieldOptions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserProfileField = /* GraphQL */ `
  mutation UpdateUserProfileField(
    $input: UpdateUserProfileFieldInput!
    $condition: ModelUserProfileFieldConditionInput
  ) {
    updateUserProfileField(input: $input, condition: $condition) {
      id
      title
      description
      placeholder
      slug
      instruction
      type
      visibility
      profileType
      profileGroup
      position
      status
      required
      fieldOptions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserProfileField = /* GraphQL */ `
  mutation DeleteUserProfileField(
    $input: DeleteUserProfileFieldInput!
    $condition: ModelUserProfileFieldConditionInput
  ) {
    deleteUserProfileField(input: $input, condition: $condition) {
      id
      title
      description
      placeholder
      slug
      instruction
      type
      visibility
      profileType
      profileGroup
      position
      status
      required
      fieldOptions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProfileField = /* GraphQL */ `
  mutation CreateProfileField(
    $input: CreateProfileFieldInput!
    $condition: ModelProfileFieldConditionInput
  ) {
    createProfileField(input: $input, condition: $condition) {
      id
      title
      description
      placeholder
      slug
      instruction
      type
      visibility
      profileType
      profileGroup
      position
      status
      required
      fieldOptions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateProfileField = /* GraphQL */ `
  mutation UpdateProfileField(
    $input: UpdateProfileFieldInput!
    $condition: ModelProfileFieldConditionInput
  ) {
    updateProfileField(input: $input, condition: $condition) {
      id
      title
      description
      placeholder
      slug
      instruction
      type
      visibility
      profileType
      profileGroup
      position
      status
      required
      fieldOptions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteProfileField = /* GraphQL */ `
  mutation DeleteProfileField(
    $input: DeleteProfileFieldInput!
    $condition: ModelProfileFieldConditionInput
  ) {
    deleteProfileField(input: $input, condition: $condition) {
      id
      title
      description
      placeholder
      slug
      instruction
      type
      visibility
      profileType
      profileGroup
      position
      status
      required
      fieldOptions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cid
      username
      firstname
      lastname
      email
      phone
      about
      bio
      dob
      gender
      role
      age
      accountType
      status
      deeplink
      avatar
      coverPhoto
      language
      lastActive
      UserFields {
        nextToken
        startedAt
        __typename
      }
      Feeds {
        nextToken
        startedAt
        __typename
      }
      Views {
        nextToken
        startedAt
        __typename
      }
      Shares {
        nextToken
        startedAt
        __typename
      }
      Likes {
        nextToken
        startedAt
        __typename
      }
      UserAddresses {
        nextToken
        startedAt
        __typename
      }
      UserOptions {
        nextToken
        startedAt
        __typename
      }
      Pets {
        nextToken
        startedAt
        __typename
      }
      Businesses {
        nextToken
        startedAt
        __typename
      }
      Products {
        nextToken
        startedAt
        __typename
      }
      Events {
        nextToken
        startedAt
        __typename
      }
      Groups {
        nextToken
        startedAt
        __typename
      }
      News {
        nextToken
        startedAt
        __typename
      }
      Deals {
        nextToken
        startedAt
        __typename
      }
      BlockUsers {
        nextToken
        startedAt
        __typename
      }
      Jobs {
        nextToken
        startedAt
        __typename
      }
      Messages {
        nextToken
        startedAt
        __typename
      }
      FriendShips {
        nextToken
        startedAt
        __typename
      }
      Reports {
        nextToken
        startedAt
        __typename
      }
      Follows {
        nextToken
        startedAt
        __typename
      }
      Claims {
        nextToken
        startedAt
        __typename
      }
      Reviews {
        nextToken
        startedAt
        __typename
      }
      Uploads {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cid
      username
      firstname
      lastname
      email
      phone
      about
      bio
      dob
      gender
      role
      age
      accountType
      status
      deeplink
      avatar
      coverPhoto
      language
      lastActive
      UserFields {
        nextToken
        startedAt
        __typename
      }
      Feeds {
        nextToken
        startedAt
        __typename
      }
      Views {
        nextToken
        startedAt
        __typename
      }
      Shares {
        nextToken
        startedAt
        __typename
      }
      Likes {
        nextToken
        startedAt
        __typename
      }
      UserAddresses {
        nextToken
        startedAt
        __typename
      }
      UserOptions {
        nextToken
        startedAt
        __typename
      }
      Pets {
        nextToken
        startedAt
        __typename
      }
      Businesses {
        nextToken
        startedAt
        __typename
      }
      Products {
        nextToken
        startedAt
        __typename
      }
      Events {
        nextToken
        startedAt
        __typename
      }
      Groups {
        nextToken
        startedAt
        __typename
      }
      News {
        nextToken
        startedAt
        __typename
      }
      Deals {
        nextToken
        startedAt
        __typename
      }
      BlockUsers {
        nextToken
        startedAt
        __typename
      }
      Jobs {
        nextToken
        startedAt
        __typename
      }
      Messages {
        nextToken
        startedAt
        __typename
      }
      FriendShips {
        nextToken
        startedAt
        __typename
      }
      Reports {
        nextToken
        startedAt
        __typename
      }
      Follows {
        nextToken
        startedAt
        __typename
      }
      Claims {
        nextToken
        startedAt
        __typename
      }
      Reviews {
        nextToken
        startedAt
        __typename
      }
      Uploads {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cid
      username
      firstname
      lastname
      email
      phone
      about
      bio
      dob
      gender
      role
      age
      accountType
      status
      deeplink
      avatar
      coverPhoto
      language
      lastActive
      UserFields {
        nextToken
        startedAt
        __typename
      }
      Feeds {
        nextToken
        startedAt
        __typename
      }
      Views {
        nextToken
        startedAt
        __typename
      }
      Shares {
        nextToken
        startedAt
        __typename
      }
      Likes {
        nextToken
        startedAt
        __typename
      }
      UserAddresses {
        nextToken
        startedAt
        __typename
      }
      UserOptions {
        nextToken
        startedAt
        __typename
      }
      Pets {
        nextToken
        startedAt
        __typename
      }
      Businesses {
        nextToken
        startedAt
        __typename
      }
      Products {
        nextToken
        startedAt
        __typename
      }
      Events {
        nextToken
        startedAt
        __typename
      }
      Groups {
        nextToken
        startedAt
        __typename
      }
      News {
        nextToken
        startedAt
        __typename
      }
      Deals {
        nextToken
        startedAt
        __typename
      }
      BlockUsers {
        nextToken
        startedAt
        __typename
      }
      Jobs {
        nextToken
        startedAt
        __typename
      }
      Messages {
        nextToken
        startedAt
        __typename
      }
      FriendShips {
        nextToken
        startedAt
        __typename
      }
      Reports {
        nextToken
        startedAt
        __typename
      }
      Follows {
        nextToken
        startedAt
        __typename
      }
      Claims {
        nextToken
        startedAt
        __typename
      }
      Reviews {
        nextToken
        startedAt
        __typename
      }
      Uploads {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFilterCategory = /* GraphQL */ `
  mutation CreateFilterCategory(
    $input: CreateFilterCategoryInput!
    $condition: ModelFilterCategoryConditionInput
  ) {
    createFilterCategory(input: $input, condition: $condition) {
      id
      categoryName
      subCategory {
        id
        subCategoryName
        __typename
      }
      icon
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFilterCategory = /* GraphQL */ `
  mutation UpdateFilterCategory(
    $input: UpdateFilterCategoryInput!
    $condition: ModelFilterCategoryConditionInput
  ) {
    updateFilterCategory(input: $input, condition: $condition) {
      id
      categoryName
      subCategory {
        id
        subCategoryName
        __typename
      }
      icon
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFilterCategory = /* GraphQL */ `
  mutation DeleteFilterCategory(
    $input: DeleteFilterCategoryInput!
    $condition: ModelFilterCategoryConditionInput
  ) {
    deleteFilterCategory(input: $input, condition: $condition) {
      id
      categoryName
      subCategory {
        id
        subCategoryName
        __typename
      }
      icon
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPostCategory = /* GraphQL */ `
  mutation CreatePostCategory(
    $input: CreatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    createPostCategory(input: $input, condition: $condition) {
      id
      postCategoryName
      icon
      postSubCategory {
        id
        postSubCategoryName
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePostCategory = /* GraphQL */ `
  mutation UpdatePostCategory(
    $input: UpdatePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    updatePostCategory(input: $input, condition: $condition) {
      id
      postCategoryName
      icon
      postSubCategory {
        id
        postSubCategoryName
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePostCategory = /* GraphQL */ `
  mutation DeletePostCategory(
    $input: DeletePostCategoryInput!
    $condition: ModelPostCategoryConditionInput
  ) {
    deletePostCategory(input: $input, condition: $condition) {
      id
      postCategoryName
      icon
      postSubCategory {
        id
        postSubCategoryName
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserFriendShip = /* GraphQL */ `
  mutation CreateUserFriendShip(
    $input: CreateUserFriendShipInput!
    $condition: ModelUserFriendShipConditionInput
  ) {
    createUserFriendShip(input: $input, condition: $condition) {
      id
      friendShipId
      userId
      friendShip {
        id
        status
        description
        senderID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserFriendShip = /* GraphQL */ `
  mutation UpdateUserFriendShip(
    $input: UpdateUserFriendShipInput!
    $condition: ModelUserFriendShipConditionInput
  ) {
    updateUserFriendShip(input: $input, condition: $condition) {
      id
      friendShipId
      userId
      friendShip {
        id
        status
        description
        senderID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserFriendShip = /* GraphQL */ `
  mutation DeleteUserFriendShip(
    $input: DeleteUserFriendShipInput!
    $condition: ModelUserFriendShipConditionInput
  ) {
    deleteUserFriendShip(input: $input, condition: $condition) {
      id
      friendShipId
      userId
      friendShip {
        id
        status
        description
        senderID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        cid
        username
        firstname
        lastname
        email
        phone
        about
        bio
        dob
        gender
        role
        age
        accountType
        status
        deeplink
        avatar
        coverPhoto
        language
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatRoomChatRoomUser = /* GraphQL */ `
  mutation CreateChatRoomChatRoomUser(
    $input: CreateChatRoomChatRoomUserInput!
    $condition: ModelChatRoomChatRoomUserConditionInput
  ) {
    createChatRoomChatRoomUser(input: $input, condition: $condition) {
      id
      chatRoomUserId
      chatRoomId
      chatRoomUser {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomUserUserId
        __typename
      }
      chatRoom {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatRoomChatRoomUser = /* GraphQL */ `
  mutation UpdateChatRoomChatRoomUser(
    $input: UpdateChatRoomChatRoomUserInput!
    $condition: ModelChatRoomChatRoomUserConditionInput
  ) {
    updateChatRoomChatRoomUser(input: $input, condition: $condition) {
      id
      chatRoomUserId
      chatRoomId
      chatRoomUser {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomUserUserId
        __typename
      }
      chatRoom {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatRoomChatRoomUser = /* GraphQL */ `
  mutation DeleteChatRoomChatRoomUser(
    $input: DeleteChatRoomChatRoomUserInput!
    $condition: ModelChatRoomChatRoomUserConditionInput
  ) {
    deleteChatRoomChatRoomUser(input: $input, condition: $condition) {
      id
      chatRoomUserId
      chatRoomId
      chatRoomUser {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomUserUserId
        __typename
      }
      chatRoom {
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        chatRoomLastMessageId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
