import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import editIcon from "../../../assets/images/edit-2.svg";
import deleteIcon from "../../../assets/images/trash.png";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { style } from "./AddUserCss";
import { RiCloseFill } from "react-icons/ri";
import InputField from "../../comman/component/CustomInput";
import ConfirmationDeleteModal from "../../comman/component/ConfirmationDeleteModal";

const AddUser = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [editedId, setEditedId] = useState(null);
  const [addUserData, setAddUserData] = useState({
    name: "",
    email: "",
    password: "",
    sub_admin: true,
  });
  const [deleteModalTableId, setDeleteModalTableId] = useState("");
  const [modalSubAdminName, setModalSubAdminName] = useState("");

  // handle change event

  const handleChange = (e) => {
    setAddUserData({ ...addUserData, [e.target.name]: e.target.value });
  };

  // get app data

  const getAppData = async () => {
    setLoading(true);
    try {
      const res = await getRequest("admin/subadmin-list");
      const data = res.data.data;
      setUserData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAppData();
  }, []);

  // delete user

  const handleDeleteSubAdmin = async () => {
    setSubmitLoading(true);
    try {
      const response = await deleteRequest(
        `admin/delete-subadmin?id=${deleteModalTableId}`
      );
      if (response.response.data.success === true) {
        getAppData();
        handleDeleteCloseConfirmModal();
        setSubmitLoading(false);
      } else {
        errorNotification(response.data.errormessage);
        setSubmitLoading(false);
      }
      setSubmitLoading(false);
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
    }
  };

  // add app data

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const res = await postRequest("admin/create-admin", addUserData, null);
      if (res.type === 1) {
        handleCloseModal();
        successNotification(res?.response?.data?.message);
        setAddUserData({
          name: "",
          email: "",
          password: "",
          sub_admin: true,
        });
        getAppData();
      } else {
        errorNotification(res.errormessage);
        setSubmitLoading(false);
      }
      setSubmitLoading(false);
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
    }
  };

  // add category modal event

  const handleAddCategory = () => {
    setOpenModal(true);
  };

  // close modal event

  const handleCloseModal = () => {
    setOpenModal(false);
    setAddUserData({
      name: "",
      email: "",
      password: "",
      sub_admin: true,
    });
  };

  // open modal

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // delete icon click event

  const handleDeleteModal = (row) => {
    setModalSubAdminName(row.name);
    setDeleteModalTableId(row.id);
    setDeleteModal(true);
  };

  // delete close confirm modal

  const handleDeleteCloseConfirmModal = () => {
    setDeleteModal(false);
  };

  return (
    <>
      <Paper elevation={0} sx={style.mainPadding}>
        <Box sx={style.headerPosition}>
          <Typography
            sx={style.userHeaderName}
            display="flex"
            alignItems="center"
          >
            <Typography sx={style.subAdminHeaderName} px={2}>
              Sub Admin
            </Typography>
          </Typography>
          <Button
            variant="outlined"
            sx={style.addBtnStyleOne}
            onClick={handleOpenModal}
          >
            ADD
          </Button>
        </Box>
        <Divider sx={style.headerLine}></Divider>
        <Box sx={style.bodyStyleHeight} className="tableScrollNone">
          {loading ? (
            <Grid item xs={12} sx={style.loaderPosition}>
              <CircularProgress sx={{ color: "#E75126" }} />
            </Grid>
          ) : userData.length === 0 ? (
            <Box sx={style.noDataFoundText}>
              <Typography sx={style.noDataFound}>No Data</Typography>
            </Box>
          ) : (
            <Box sx={style.mainPart}>
              <Paper variant="outlined">
                <TableContainer
                  sx={style.userBoxStyle}
                  className="tableScrollNone"
                >
                  <Table stickyHeader={true} aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={style.tableHeaderTextColor}>
                          No.
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor}>
                          Name
                        </TableCell>
                        <TableCell sx={style.tableHeaderTextColor} align="left">
                          Email
                        </TableCell>
                        <TableCell
                          sx={style.tableHeaderTextColor}
                          align="right"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.map((user, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.userFontStyle}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.userFontStyle}
                          >
                            {user.name}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={style.userFontStyle}
                          >
                            {user.email}
                          </TableCell>
                          <TableCell align="right">
                            <Typography display="flex" justifyContent="end">
                              {/* <img
                                src={editIcon}
                              /> */}
                              <Typography px={2} sx={{ cursor: "pointer" }}>
                                <img
                                  src={deleteIcon}
                                  onClick={() => handleDeleteModal(user)}
                                />
                              </Typography>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          )}
        </Box>
        <ConfirmationDeleteModal
          handleDeleteCloseConfirmModal={handleDeleteCloseConfirmModal}
          deleteModal={deleteModal}
          handleDeleteApiCall={handleDeleteSubAdmin}
          text={`Are you sure delete this "${modalSubAdminName}" sub admin?`}
          submitLoading={submitLoading}
        />
      </Paper>

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <form onSubmit={handleSubmit}>
            <Grid
              sx={style.modalMainPart}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box sx={style.modalHeaderStyle}>
                {editedId ? "Edit" : "Add"} App
              </Box>
              <Grid textAlign="end">
                <RiCloseFill
                  onClick={() => handleCloseModal()}
                  size="22"
                  cursor="pointer"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <InputField
                  type="text"
                  required={true}
                  value={addUserData.name}
                  placeholder="Enter Name"
                  name="name"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  type="text"
                  required={true}
                  value={addUserData.email}
                  placeholder="Enter Email"
                  name="email"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  type="text"
                  required={true}
                  value={addUserData.password}
                  placeholder="Enter Password"
                  name="password"
                  onChange={handleChange}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={style.addBtnStyle}
                mb={2}
                mt={2}
                display="flex"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={style.submitBtnStyle}
                  disabled={submitLoading}
                >
                  {submitLoading && (
                    <CircularProgress
                      size={16}
                      sx={{ marginRight: "10px", color: "#fff" }}
                    />
                  )}
                  {editedId ? "Save" : "Add"}
                </Button>
                <Button
                  variant="outlined"
                  sx={style.cancelBtn}
                  onClick={() => handleCloseModal()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddUser;
