import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Grid,
  Modal,
} from "@mui/material";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import InputField from "../../comman/component/CustomInput";
import { RiCloseFill } from "react-icons/ri";
import editIcon from "../../../assets/images/edit-2.svg";
import deleteIcon from "../../../assets/images/trash.png";
import { style } from "./preferdLanguageCss";
import ConfirmationDeleteModal from "../../comman/component/ConfirmationDeleteModal";

const PreferedLanguages = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [languageData, setLanguageData] = useState([]);
  const [languageName, setLanguageName] = useState("");
  const [deleteModalTableId, setDeleteModalTableId] = useState("");
  const [modalLanguageName, setModalLanguageName] = useState("");

  // get language data

  const getLanguageData = async () => {
    setLoading(true);
    try {
      const res = await getRequest(`prefered_lang/get-lang?app_id=${2}`);
      const data = res.data.data.items;
      setLanguageData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getLanguageData();
  }, []);

  // edit and add language data

  const handleEditLanguageData = async (editRowData) => {
    setEditedId(editRowData.id);
    setLanguageName(editRowData.label);
    setOpenModal(true);
  };

  // add and edit data

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editedId) {
      const data = {
        label: languageName,
        app_id: 2,
      };
      try {
        const response = await patchRequest(
          `prefered_lang/update-lang/${editedId}`,
          data
        );
        if (response?.data?.success === true) {
          handleCloseModal();
          setEditedId(null);
          successNotification(response.data?.message);
          getLanguageData();
          setSubmitLoading(false);
          setLanguageName("");
        } else {
          errorNotification(response.data.errormessage);
          setSubmitLoading(false);
        }
        setSubmitLoading(false);
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    } else {
      try {
        const data = {
          label: languageName,
          app_id: 2,
        };
        const res = await postRequest("prefered_lang/add-lang", data, null);
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          handleCloseModal();
          getLanguageData();
          setLanguageName("");
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
        }
        setSubmitLoading(false);
      } catch (error) {
        console.log(error);
        setSubmitLoading(false);
      }
    }
  };

  // delete language data

  const handleDeleteLanguage = async () => {
    setSubmitLoading(true);
    try {
      const response = await deleteRequest(
        `prefered_lang/delete-lang/${deleteModalTableId}?app_id=${2}`
      );
      if (response.response.data.success === true) {
        successNotification(response.data?.message);
        getLanguageData();
        handleDeleteCloseConfirmModal();
        setSubmitLoading(false);
      } else {
        errorNotification(response.data.errormessage);
        setSubmitLoading(false);
      }
      setSubmitLoading(false);
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
    }
  };

  // add category modal event

  const handleAddCategory = () => {
    setOpenModal(true);
  };

  // close modal event

  const handleCloseModal = () => {
    setEditedId(null);
    setOpenModal(false);
    setLanguageName("");
  };

  // delete icon click event

  const handleDeleteModal = (row) => {
    setModalLanguageName(row.label);
    setDeleteModalTableId(row.id);
    setDeleteModal(true);
  };

  // delete close confirm modal

  const handleDeleteCloseConfirmModal = () => {
    setDeleteModal(false);
  };

  return (
    <>
      <Paper elevation={0} sx={style.mainPadding}>
        <Box sx={style.languageHeaderPosition}>
          <Typography sx={style.languageHeaderName} px={4} display="flex">
            Languages
          </Typography>
          <Button
            variant="outlined"
            sx={style.addBtnStyleOne}
            onClick={() => handleAddCategory()}
          >
            Add
          </Button>
        </Box>
        <Divider sx={style.headerLine} />
        <Box sx={style.bodyStyleHeight} className="tableScrollNone">
          <Grid>
            {loading ? (
              <Grid item xs={12} sx={style.loaderPosition}>
                <CircularProgress sx={{ color: "#E75126" }} />
              </Grid>
            ) : languageData.length === 0 ? (
              <Box sx={style.noDataFoundText}>
                <Typography sx={style.noDataFound}>No Data</Typography>
              </Box>
            ) : (
              <Box sx={style.mainPart}>
                <Paper variant="outlined">
                  <TableContainer
                    sx={style.languageBoxStyle}
                    className="tableScrollNone"
                  >
                    <Table stickyHeader={true} aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={style.tableHeaderTextColor}>
                            No.
                          </TableCell>
                          <TableCell sx={style.tableHeaderTextColor}>
                            Language
                          </TableCell>
                          <TableCell sx={style.tableHeaderTextColor}>
                            Slug
                          </TableCell>
                          <TableCell
                            sx={style.tableHeaderTextColor}
                            align="right"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {languageData.map((language, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.languageFontStyle}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.languageFontStyle}
                            >
                              {language.label}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.languageFontStyle}
                            >
                              {language.slug}
                            </TableCell>

                            <TableCell align="right">
                              <Typography
                                display="flex"
                                justifyContent="end"
                                sx={style.languageFontStyleCursor}
                              >
                                <img
                                  src={editIcon}
                                  onClick={() =>
                                    handleEditLanguageData(language)
                                  }
                                />
                                <Typography pl={3}>
                                  <img
                                    src={deleteIcon}
                                    onClick={() => handleDeleteModal(language)}
                                  />
                                </Typography>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            )}
          </Grid>
        </Box>
        <ConfirmationDeleteModal
          handleDeleteCloseConfirmModal={handleDeleteCloseConfirmModal}
          deleteModal={deleteModal}
          handleDeleteApiCall={handleDeleteLanguage}
          text={`Are you sure delete this language "${modalLanguageName}"?`}
          submitLoading={submitLoading}
        />
      </Paper>

      {/* modal  */}

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style.modal}>
          <form onSubmit={handleSubmit}>
            <Grid container sx={style.modalMainPart}>
              <Grid item xs={10} sx={style.modalHeaderStyle} mt={2}>
                {editedId ? "Edit" : "Add"} Language
              </Grid>
              <Grid item xs={2} textAlign="end">
                <RiCloseFill onClick={() => handleCloseModal()} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={3}>
                <InputField
                  type="text"
                  placeholder="Enter Language Name"
                  value={languageName}
                  onChange={(e) => setLanguageName(e.target.value)}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sx={style.addBtnStyle} mb={5} mt={1}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={style.submitBtnStyle}
                  disabled={submitLoading}
                >
                  {submitLoading && (
                    <CircularProgress size={16} sx={style.submitLoading} />
                  )}
                  {editedId ? "Save" : "Add"}
                </Button>
                <Button
                  variant="outlined"
                  sx={style.cancelBtn}
                  onClick={() => handleCloseModal()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default PreferedLanguages;
