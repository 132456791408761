export const style = {
  addBtnStyleOne: {
    color: "#E75126",
    borderColor: "#D2D2D2",
    height: "36px",
    paddingRight: "20px",
    paddingLeft: "20px",
    textTransform: "capitalize",
    ":hover": {
      borderColor: "#E75126",
      bgcolor: "#fcf1eb",
    },
  },
};
