export const style = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    width: "30%",
    maxHeight: "90vh",
    overflowY: "auto",
    backgroundColor: "white",
    "@media(max-Width: 1192px)": {
      width: "40%",
    },
    "@media(max-Width: 885px)": {
      width: "60%",
    },
    "@media(max-Width: 600px)": {
      width: "80%",
    },
    padding: "20px",
  },

  inputLabel: {
    color: "black",
    fontWeight: 500,
    fontSize: "16px",
    marginBottom: "0",
  },
  modalHeaderStyle: {
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "16px",
    "@media(max-Width: 450px)": {
      fontSize: "18px",
    },
  },
  submitBtnStyle: {
    backgroundColor: "#E75126",
    padding: "6px 45px",
    fontSize: "18px",
    textTransform: "capitalize",
    borderRadius: "10px",
    "@media(max-Width: 450px)": {
      padding: "3px 30px",
      fontSize: "16px",
    },
    ":hover": {
      bgcolor: "#E75126",
    },
  },
  cancelBtn: {
    borderColor: "#D2D2D2",
    color: "black",
    padding: "6px 35px",
    fontSize: "18px",
    textTransform: "capitalize",
    marginLeft: "20px",
    borderRadius: "10px",
    "@media(max-Width: 450px)": {
      padding: "3px 20px",
      fontSize: "16px",
      marginLeft: "5px",
    },
    ":hover": {
      borderColor: "#E75126",
      bgcolor: "#fcf1eb",
    },
  },
  headerPosition: {
    display: "flex",
    padding: "12px",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    marginLeft: "10px",
    "@media(max-Width: 600px)": {
      padding: "0px",
    },
  },
  categoryMainPadding: {
    paddingBottom: "20px",
    minHeight: "calc(100vh - 105px)",
  },
  mainPadding: {
    paddingBottom: "20px",
    "@media(max-Width: 600px)": {
      paddingBottom: "10px",
    },
  },
  appHeaderName: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#000000",
  },
  addBtnStyleOne: {
    fontSize: "16px",
    color: "#E75126",
    borderColor: "#D2D2D2",
    paddingRight: "25px",
    paddingLeft: "25px",
    marginRight: "25px",
    paddingTop: "2px",
    paddingBottom: "2px",
    ":hover": {
      borderColor: "#E75126",
      bgcolor: "#fcf1eb",
    },
    "@media(max-Width: 600px)": {
      fontSize: "12px",
      paddingRight: "10px",
      paddingLeft: "10px",
      marginRight: "20px",
    },
  },
  addBtnStyle: {
    fontSize: "18px",
    color: "#E75126",
    borderColor: "#D2D2D2",
    height: "36px",
    paddingRight: "30px",
    paddingLeft: "30px",
    textTransform: "capitalize",
  },
  headerLine: {
    backgroundColor: "#D2D2D2",
  },
  categoryHeader: {
    fontWeight: "600",
    fontSize: "20px",
    "@media(max-Width: 900px)": {
      fontSize: "18px",
    },
  },
  categoryBoxStyle: {
    fontSize: "18px",
    maxHeight: "calc(100vh - 210px)",
  },
  tableHeaderTextColor: {
    fontSize: "18px",
    color: "black",
    backgroundColor: "#fcf1eb",
    "@media(max-Width: 600px)": {
      fontSize: "16px",
    },
  },
  bodyStyleHeight: {
    height: "calc(100vh - 186px)",
    overflow: "auto",
  },
  mainPart: {
    padding: "20px 34px 0px 34px",
    "@media(max-Width: 600px)": {
      padding: "15px 18px 0px 18px",
    },
  },
  loaderPosition: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 250px)",
  },
  noDataFoundText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 250px)",
  },
  noDataFound: {
    color: "#ACACAC",
    fontSize: "25px",
  },
  categoryFontStyle: {
    fontSize: "16px",
  },
  categoryFontStyleCursor: {
    fontSize: "16px",
    cursor: "pointer",
    "@media(max-Width: 600px)": {
      fontSize: "14px",
    },
  },
  csvModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: "10px",
    border: "none",
    padding: "20px",
    "@media(max-Width: 1192px)": {
      width: "40%",
    },
    "@media(max-Width: 885px)": {
      width: "50%",
    },
    "@media(max-Width: 600px)": {
      width: "80%",
    },
  },
  csvBtnStyle: {
    backgroundColor: "#E75126",
    padding: "6px 20px",
    fontSize: "18px",
    textTransform: "capitalize",
    borderRadius: "10px",
    "@media(max-Width: 450px)": {
      padding: "3px 20px",
      fontSize: "16px",
    },
    ":hover": {
      bgcolor: "#E75126",
    },
  },
  dataOverFlow: {
    padding: "5px",
    maxHeight: "280px",
    overflowY: "scroll",
  },
  subCategoryRemoveIcon: {
    cursor: "pointer",
    position: "absolute",
    top: "-7px",
    right: "-7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: "50%",
    height: "14px",
    width: "14px",
  },
};
