import { Box, Grid } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

const ContentLayout = () => {
  return (
    <>
      <Grid
        sx={{
          paddingLeft: { md: "245px", xs: "0px" },
          paddingRight: { md: "0", xs: "0px" },
          paddingTop: { sm: "64px", xs: "56px" },
          paddingBottom: "0",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Box
          sx={{
            height: { sm: "calc(100vh - 64px)", xs: "calc(100vh - 56px)" },
            overflow: "auto",
            padding: "20px",
          }}
        >
          <Outlet />
        </Box>
      </Grid>
    </>
  );
};

export default ContentLayout;
