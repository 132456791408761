import { Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import './App.css';
import Login from './components/auth/login/Login';
import ContentLayout from "./components/comman/ContentLayout";
import { Layout } from "./components/comman/Layout";
import PostCategory from "./components/pages/postCategory/PostCategory";
import { ToastContainer } from "react-toastify";
import PreferedLanguages from "./components/pages/preferedLanguages/PreferedLanguages";
import ProfileTypes from "./components/pages/profileTypes/ProfileTypes";
import CategoryTwo from "./components/pages/category/CategoryTwo";
import SubCategory from "./components/pages/category/SubCategory";
import AppMulti from "./components/pages/app/AppsMulti";
import AddUser from "./components/pages/addUser/AddUser";
import Users from "./components/pages/users/Users";
import AppsUsers from "./components/pages/users/AppsUsers";
import UserProfile from "./components/pages/users/UserProfile";
import { ThemeProvider, createTheme } from "@mui/material";
const theme = createTheme();
function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route element={<Layout hidePaths="/" />}>
            <Route path="/" element={<Login />} />
            <Route element={<ContentLayout />} >
              <Route path="/apps" element={<AppMulti />} />
              <Route path="/apps/:id" element={<CategoryTwo />} />
              <Route path="/apps/:id/:subId" element={<SubCategory />} />
              <Route path="/postCategory" element={<PostCategory />} />
              <Route path="/subAdmin" element={<AddUser />} />
              <Route path="/preferedLanguages" element={<PreferedLanguages />} />
              <Route path="/profileTypes" element={<ProfileTypes />} />
              <Route path="/appsUsers" element={<AppsUsers />} />
              <Route path="/appsUsers/:appUsersId" element={<Users />} />
              <Route path="/appsUsers/:appUsersId/:userId" element={<UserProfile />} />
            </Route>
          </Route>
        </Routes></ThemeProvider>
      <ToastContainer />
    </>
  );
}

export default App;
