export const style = {
  wordWrap: {
    wordBreak: "break-word",
    marginTop: "10px",
  },
  mainPart: {
    padding: "20px 34px 0px 34px",
    "@media(max-Width: 600px)": {
      padding: "15px 18px 0px 18px",
    },
  },
  mainPadding: {
    paddingBottom: "20px",
    "@media(max-Width: 600px)": {
      paddingBottom: "10px",
    },
  },
  userProfileGridSystem: {
    boxShadow: "0px 0px 10px 0px #00000040",
    borderRadius: "20px",
    padding: "40px 40px 40px 40px",
  },
  userProfileHeaderPosition: {
    display: "flex",
    padding: "12px",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    "@media(max-Width: 600px)": {
      padding: "0px",
    },
  },
  userProfileHeaderName: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#000000",
    marginLeft: "10px",
  },
  bodyStyleHeight: {
    height: "calc(100vh - 186px)",
    overflow: "auto",
    padding: "20px 35px 20px 35px",
  },
  loaderPosition: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 125px)",
  },
};
