export const style = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    width: "30%",
    maxHeight: "90vh",
    overflowY: "auto",
    backgroundColor: "white",
    "@media(max-Width: 1192px)": {
      width: "40%",
    },
    "@media(max-Width: 885px)": {
      width: "60%",
    },
    "@media(max-Width: 600px)": {
      width: "80%",
    },
    padding: "20px",
  },
  inputLabel: {
    color: "black",
    fontWeight: 500,
    fontSize: "16px",
    marginBottom: "0",
  },
  modalHeaderStyle: {
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "16px",
    "@media(max-Width: 450px)": {
      fontSize: "18px",
    },
  },
  submitBtnStyle: {
    backgroundColor: "#E75126",
    padding: "6px 45px",
    fontSize: "18px",
    textTransform: "capitalize",
    borderRadius: "10px",
    "@media(max-Width: 450px)": {
      padding: "3px 30px",
      fontSize: "16px",
    },
    ":hover": {
      bgcolor: "#E75126",
    },
  },
  cancelBtn: {
    borderColor: "#D2D2D2",
    color: "black",
    padding: "6px 35px",
    fontSize: "18px",
    textTransform: "capitalize",
    marginLeft: "20px",
    borderRadius: "10px",
    "@media(max-Width: 450px)": {
      padding: "3px 20px",
      fontSize: "16px",
      marginLeft: "5px",
    },
    ":hover": {
      borderColor: "#E75126",
      bgcolor: "#fcf1eb",
    },
  },
  headerLine: {
    backgroundColor: "#D2D2D2",
  },
  mainPadding: {
    paddingBottom: "20px",
  },
  profileHeaderPosition: {
    display: "flex",
    padding: "12px",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
  },
  mainPart: {
    padding: "20px 34px 0px 34px",
  },
  bodyStyleHeight: {
    height: "calc(100vh - 186px)",
    overflow: "auto",
  },
  languageBoxStyle: {
    fontSize: "18px",
    maxHeight: "747px",
  },
  tableHeaderTextColor: {
    fontSize: "18px",
    color: "black",
    backgroundColor: "#fcf1eb",
  },
  loaderPosition: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 250px)",
  },
  noDataFoundText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 250px)",
  },
  profileHeaderName: {
    fontSize: "20px",
    fontWeight: "400",
    color: "#000000",
  },
  addBtnStyleOne: {
    fontSize: "18px",
    color: "#E75126",
    borderColor: "#D2D2D2",
    height: "36px",
    paddingRight: "30px",
    paddingLeft: "30px",
    marginRight: "25px",
    textTransform: "capitalize",
    ":hover": {
      borderColor: "#E75126",
      bgcolor: "#fcf1eb",
    },
  },
  addBtnStyle: {
    fontSize: "18px",
    color: "#E75126",
    borderColor: "#D2D2D2",
    height: "36px",
    paddingRight: "30px",
    paddingLeft: "30px",
    textTransform: "capitalize",
  },
  profileFontStyle: {
    fontSize: "16px",
  },
  profileFontStyleCursor: {
    fontSize: "16px",
    cursor: "pointer",
  },
};
