import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { style } from "./UsersCss";
import {
  Box,
  Paper,
  Typography,
  Divider,
  Grid,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
} from "@mui/material";
import userProfileIcon from "../../../assets/images/user.png";
import { getRequest } from "../../../helper/AxiosClient";
import backArrow from "../../../assets/images/arrow-left.png";
import { AiOutlineEye } from "react-icons/ai";

const Users = () => {
  const navigate = useNavigate();
  const { appUsersId } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [userData, setUserData] = useState([]);
  const minAppTitle = location.state.data.app_name;

  // page change

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // get user data

  const getUsersData = async () => {
    setLoading(true);
    try {
      const res = await getRequest(
        `admin/get-user-list?app_id=${appUsersId}&page=${page}&perPage=10`
      );
      const data = res.data.data.users;
      setUserData(data);
      setTotalPages(Math.ceil(res.data.data.total / 10));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsersData();
  }, [page]);

  return (
    <>
      <Paper elevation={0} sx={style.mainPadding}>
        <Box sx={style.userHeaderPosition}>
          <Typography
            sx={style.usersHeaderName}
            display="flex"
            alignItems="center"
          >
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/appsUsers")}
            >
              <img src={backArrow} />
            </Typography>
            <Typography px={2}>{minAppTitle}</Typography>
          </Typography>
        </Box>
        <Divider sx={style.headerLine}></Divider>
        <Box sx={style.bodyStyleHeight} className="tableScrollNone">
          <Grid>
            {loading ? (
              <Grid item xs={12} sx={style.loaderPosition}>
                <CircularProgress sx={{ color: "#E75126" }} />
              </Grid>
            ) : userData.length === 0 ? (
              <Box sx={style.noDataFoundText}>
                <Typography sx={style.noDataFound}>No Data</Typography>
              </Box>
            ) : (
              <Box sx={style.mainPart}>
                <Paper variant="outlined">
                  <TableContainer
                    sx={style.usersBoxStyle}
                    className="tableScrollNone"
                  >
                    <Table stickyHeader={true} aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            width="10%"
                            sx={style.tableHeaderTextColor}
                          >
                            Id
                          </TableCell>
                          <TableCell
                            width="15%"
                            sx={style.tableHeaderTextColor}
                          >
                            Profile
                          </TableCell>
                          <TableCell
                            width="20%"
                            sx={style.tableHeaderTextColor}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            width="49%"
                            sx={style.tableHeaderTextColor}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            width="6%"
                            align="center"
                            sx={style.tableHeaderTextColor}
                          >
                            View
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userData.map((user, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.userFontStyle}
                              width="10%"
                            >
                              {user.id}
                            </TableCell>

                            <TableCell
                              sx={{ width: "15%" }}
                              component="th"
                              scope="row"
                            >
                              <Typography
                                width="fit-content"
                                sx={style.userFontStyleCursor}
                              >
                                <img
                                  height="25px"
                                  width="25px"
                                  src={
                                    user?.profile_url
                                      ? user?.profile_url
                                      : userProfileIcon
                                  }
                                  alt="user-profile"
                                  style={{ borderRadius: "50%" }}
                                />
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={style.userFontStyle}
                              width="20%"
                            >
                              {user.username}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              width="49%"
                              sx={style.userFontStyle}
                            >
                              {user.email}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              sx={{ width: "6%" }}
                            >
                              <AiOutlineEye
                                size={20}
                                cursor="pointer"
                                onClick={() =>
                                  navigate(
                                    `/appsUsers/${appUsersId}/${user.id}`,
                                    {
                                      state: {
                                        data: { username: user.username },
                                      },
                                    }
                                  )
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <Box mt={2} display="flex" justifyContent="end">
                  {userData.length > 0 && (
                    <Pagination
                      count={totalPages}
                      page={page}
                      color="primary"
                      onChange={handlePageChange}
                      sx={{
                        "& .MuiPaginationItem-page.Mui-selected": {
                          color: "#E75126",
                        },
                      }}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default Users;
